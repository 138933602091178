.ListEmpty {
  padding: 24px 24px;
  width: 100%;

  .ListEmpty_Icon {
    text-align: center;
    width: 100%;
    margin-bottom: 10px;

    i {
      font-size: var(--px-40);
      line-height: var(--px-40);
      color: #aaa;
    }
  }

  .ListEmpty_Text {
    font-size: var(--px-18);
    line-height: var(--px-18);
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: #aaa;
    text-align: center;
    width: 100%;
  }
}