.Modal {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  top: -150%;
  left: 0px;
  transition: top .3s .3s ease-in-out;
  background: var(--modalBackground);
  box-shadow: 17px 17px -2px rgba(0, 0, 0, 0.08);
  padding: 0;
  display: flex;
  flex-direction: column;

  &.__is-open {
    top: 0px;
  }

  .Modal_Close {
    width: 48px;
    height: var(--px-48);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;

    i {
      font-size: var(--px-24);
      color: var(--modalClose);
    }

    &:active {
      outline: none;
      -webkit-animation: pulse;
      animation: pulse;
      -webkit-animation-duration: 0.4s;
      animation-duration: 0.4s;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .Modal_Header {
    font-family: 'Raleway', 'Open Sans', sans-serif;
    margin-bottom: auto;
    text-align: center;
    padding: var(--px-32) var(--px-40) var(--px-16) var(--px-32);
    font-size: var(--px-20);
    font-weight: 600;
    color: var(--title);
  }

  .Modal_Content {
    padding: 0px 0px;
    height: 100%;
    overflow: auto;

    .Block {
      border: none;
      box-shadow: none;
      margin-bottom: 0px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--modalScrollTrackBackground);
    }

    &::-webkit-scrollbar {
      width: var(--px-4);
      background-color: var(--modalScrollBarBackground);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--modalScrollThumbBackground);
      border: var(--modalScrollThumbBorder);
    }
  }

  .Modal_Footer {
    padding: var(--px-16) var(--px-16) var(--px-16) var(--px-16);
    margin-top: auto;

    .Modal_Footer_ListActions {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .Footer_ListActions_Item {
        padding: var(--px-4) var(--px-4);
        min-width: max-content;

        &.__is-full-width {
          width: 100%;
        }
      }
    }
  }
}