@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes button {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes button {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes anim-effect-radomir {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 1);
    transform: scale3d(0.4, 0.4, 1);
  }

  80% {
    box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
    opacity: 0.1;
  }

  100% {
    box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
    opacity: 0;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}

@keyframes anim-effect-radomir {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 1);
    transform: scale3d(0.4, 0.4, 1);
  }

  80% {
    box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
    opacity: 0.1;
  }

  100% {
    box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
    opacity: 0;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}