.ListActions,
.InsideListAction {
  width: 100%;

  .ListActions_Item {
    position: relative;
    border-bottom: 1px solid var(--listActionBorderBottom);

    &:last-child {
      border-bottom: 0;
    }

    a,
    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      width: 100%;
      padding: var(--px-16) var(--px-20);

      &:active {
        .ListActions_Item_Action {
          i {
            animation: pulse;
            animation-duration: .2s;
            color: var(--listActionItemIcon);
          }
        }
      }

      .ListActions_Item_Ico {
        position: relative;
        margin-right: 10px;

        i,
        span {
          color: var(--listActionItemIcon);
          transition: .2s ease-in-out;
          font-size: var(--px-20);
          line-height: var(--px-20px);
        }
      }

      .ListActions_Item_Label {
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
        font-size: var(--px-13);
        line-height: var(--px-18px);
        color: var(--listActionItemLabel);
      }

      .ListActions_Item_Action {
        margin-left: auto;

        i {
          font-size: var(--px-14);
          line-height: var(--px-14px);
          color: var(--listActionItemIcon);
        }
      }
    }
  }
}

.InsideListAction {
  width: 75%;
  margin: 45px auto;
  border-bottom: none;

  .ListActions_ItemInside {
    border-bottom: none;
    position: relative;
    border-bottom: 1px solid var(--listActionBorderBottom);

    &:last-child {
      border-bottom: 0;
    }

    a,
    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-decoration: none;
      width: 100%;
      padding: 0;

      &:active {
        .ListActions_Item_Action {
          i {
            animation: pulse;
            animation-duration: .2s;
            color: var(--listActionItemIcon);

          }
        }
      }

      .ListActions_Item_Ico {
        position: relative;
        margin-right: 10px;

        i,
        span {
          color: var(--listActionItemIcon);
          transition: .2s ease-in-out;
          font-size: var(--px-20);
          line-height: var(--px-20px);
        }
      }

      .ListActions_Item_Label {
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
        font-size: var(--px-13);
        line-height: var(--px-18px);
        color: var(--listActionItemLabel);
      }

      .ListActions_Item_Action {
        margin-left: auto;

        i {
          font-size: var(--px-14);
          line-height: var(--px-14px);
          color: var(--listActionItemIcon);
        }
      }
    }
  }
}

.SubHeader {
  display: flex;
  justify-content: space-between;
  background-color: var(--tertiary);
  color: var(--tertiaryContrast);
  padding: 18px 34px;
}