.Popover {
  background: var(--popoverBackground);

  .Popover_Actions  {
    ds-button {
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }
}