@mixin column-break-point {
  @media screen and (max-width: 400px) {
    @content;
  }
}

.InfoGroupList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: initial;
  }

  @include column-break-point {
    flex-direction: column;
  }
  
  .InfoGroup {
    $space: 8px;
    margin-bottom: 16px;

    &:last-child, &:nth-last-child(2) {
      margin-bottom: 0px;
    }

    &:nth-child(even) {
      margin-left: $space;
    }

    &.__is-full-width {
      flex-basis: 100%;
      max-width: 100%;
      margin: 0px 0px 16px 0px;

      & + .InfoGroup {
        margin-left: initial !important;
      }
    }

    flex-basis: calc(50% - #{$space});
    max-width: calc(50% - #{$space});

    @include column-break-point {
      max-width: 100%;
      margin-left: 0px !important;

      &:nth-last-child(2) {
        margin-bottom: 16px;
      }
    }
  }
}