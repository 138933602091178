/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// Importação do Google Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800&display=swap');

@import 'theme/animacoes.scss';
@import 'theme/estrutura/estrutura.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";

// Para animar quando a fonte mudar de tamanho (acessibilidade)
* {
  transition: font-size 0.3s ease-out;
}

// Importação de fontes Raleway 
@font-face {
  font-family: Raleway, sans-serif;
  src: url('/src/assets/font/Raleway-Regular.ttf')
}

// Correção de fundo branco devido a temificação
ion-content{
  --background: transparent;
}

textarea {
  resize: none;
}

// iPhone X
@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
  body{
    height: calc(100vh - env(safe-area-inset-top));
    margin-top: env(safe-area-inset-top);
    background: var(--bodyBackground);
  }
}

// iPhone 8
@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
  body{
    height: calc(100vh - env(safe-area-inset-top));
    margin-top: env(safe-area-inset-top);
    background: var(--bodyBackground);
  }
}

// iPhone 8 Plus
@media only screen and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3) {
  body{
    height: calc(100vh - env(safe-area-inset-top));
    margin-top: env(safe-area-inset-top);
    background: var(--bodyBackground);
  }
}


.ion-color-primary {
  --ion-color-base: var(--primary) !important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-primary-contrast-rgb,
    255,
    255,
    255
  ) !important;
  --ion-color-shade: #000000 !important;
  --ion-color-tint: var(--primary) !important;
}

.ion-color-secondary {
  --ion-color-base: var(--primary) !important;
}

ion-title {
  background-color: var(--secondary);
  color: var(--secondaryContrast);
}

ion-card-title {
  color: var(--secondary);
  font-weight: 600;
}

ion-checkbox {
  --background-checked: var(--primary);
  --border-color-checked: var(--primary);
}

p {
  font-family: "Raleway", open-sans;
}

.__is-danger {
  color: var(--danger) !important;
}

.__is-primary {
  color: var(--primary) !important;
}

// Background dos campos INPUTS gerados automaticamente pelo browser

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: inset 0 0 0 30px white inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  -webkit-text-fill-color: black !important;
}

.ion-page{
  overflow: auto !important;
}

.cordova-camera-select{
  display: none !important;
}

// CSS Adicional 
input:focus{
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}

.infinite-scroll-content-md .infinite-loading-text{
  color: var(--primary); 
}

.LoadingSplash{
  width: 100vw;
  height: 100vh;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  .LoadingSplash_Content{
    display: flex;
    justify-content: center;
    flex-direction: column;

    .LoadingSplash_Content_Logo{
      background-size: 100%;
      width: 200px;
      height: 131px;
    }

    .LoadingSplash_Content_Msg{
      font-family: 'Raleway', sans-serif;
      font-size: var(--px-14);
      line-height: var(--px-21);
      font-weight: 700;
      color: var(--secondary);
      margin-top: 16px;
      text-align: center;
    }
    .LoadingSplash_Content_Spin{
      margin-top: 32px;
      display: flex;
      justify-content: center;
      .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
      }
      .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: var(--secondary);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
      }
      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(24px, 0);
        }
      }      
    }
  }
}

.auto-width-popover {
  --width: auto;
}


.m-b-12 {
  margin-bottom: 12px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.text-align-center {
  text-align: center;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.f1 {
  flex: 1;
}

.align-items-center {
  align-items: center;
}

.z-index-4 {
  z-index: 4;
}