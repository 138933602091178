.ListCards {
  width: 100%;
  padding-bottom: 16px;

  &.__is-grid {
    @supports (display: grid) {
      .ListCards_Wrapper {
        padding: 0 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        .ListCards_Wrapper_Card {
          width: calc(100%);
          // margin: 10px;
          flex-shrink: 0;

          &:last-child {
            margin-right: 0px;
          }

          a,
          label {
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            // align-items: flex-start;
            justify-content: center;
            background: var(--atalhosBackground);
            padding: 16px 16px;
            text-decoration: none;
            height: 100%;
          }

          .Wrapper_Card_Icon {
            position: relative;

            i {
              font-size: var(--px-32);
              line-height: var(--px-32px);
            }
          }

          .Wrapper_Card_Title {
            font-family: 'Raleway', sans-serif;
            font-size: var(--px-14);
            line-height: var(--px-18px);
            color: var(--atalhosBackgroundContrast);
            font-weight: 500;
            margin-top: 8px;
          }
        }
      }
    }

    @supports (not (display: grid)) and (display: flex) {
      .ListCards_Wrapper {
        padding: 0 20px;
        display: flex;
        margin: 0 -5px;
        flex-wrap: wrap;

        .ListCards_Wrapper_Card {
          width: calc(50% - 10px);
          margin: 0px 5px 10px 5px;
          flex-shrink: 0;

          &:last-child {
            margin-right: 0px;
          }

          a,
          label {
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            // align-items: flex-start;
            justify-content: center;
            background: var(--atalhosBackground);
            padding: 16px 16px;
            text-decoration: none;
            height: 100%;
          }

          .Wrapper_Card_Icon {
            position: relative;

            i {
              font-size: var(--px-32);
              line-height: var(--px-32px);
            }
          }

          .Wrapper_Card_Title {
            font-family: 'Raleway', sans-serif;
            font-size: var(--px-14);
            line-height: var(--px-18px);
            font-weight: 500;
            color: var(--atalhosBackgroundContrast);
            margin-top: 8px;
          }
        }
      }
    }
  }

  &.__is-swipe {
    display: flex;
    overflow-x: scroll;

    .ListCards_Wrapper {
      display: flex;
      padding: 0 20px;
      width: 100%;

      .ListCards_Wrapper_Card {
        min-width: 40%;
        max-width: 40vw;
        margin: 0px 10px 10px 0;
        flex-shrink: 0;

        &:last-child {
          margin-right: 0px;
          padding-right: 10px;
          min-width: calc(40% + 10px);
        }

        a,
        label {
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          // align-items: flex-start;
          background: var(--atalhosBackground);
          padding: 16px 16px;
          text-decoration: none;
        }

        .Wrapper_Card_Icon {
          position: relative;

          i {
            font-size: var(--px-32);
            line-height: var(--px-32px);
          }
        }

        .Wrapper_Card_Title {
          font-family: 'Raleway', sans-serif;
          font-size: var(--px-14);
          line-height: var(--px-18px);
          font-weight: 500;
          color: var(--atalhosBackgroundContrast);
          margin-top: 8px;
        }
      }
    }
  }

  &.__is-floated {
    .ListCards_Wrapper {
      padding: 0 20px;
      column-count: 2;
      column-gap: 10px;

      .ListCards_Wrapper_Card {
        width: calc(100%);
        margin-bottom: 10px;
        display: inline-block;

        a,
        label {
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          // align-items: flex-start;
          background: var(--atalhosBackground);
          padding: 16px 16px;
          text-decoration: none;
          color: inherit;
        }

        .Wrapper_Card_Icon {
          position: relative;

          i {
            font-size: var(--px-32);
            line-height: var(--px-32px);
          }
        }

        .Wrapper_Card_Title {
          font-family: 'Raleway', sans-serif;
          font-size: var(--px-14);
          line-height: var(--px-18px);
          font-weight: 500;
          color: var(--atalhosBackgroundContrast);
          margin-top: 8px;
        }
      }
    }
  }

  &.__is-full-width {
    display: flex;
    overflow-x: auto;

    .ListCards_Wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      width: 100%;

      .ListCards_Wrapper_Card {
        width: 100%;
        margin: 0 0 16px 0;

        &:last-child {
          margin-bottom: 0px;
        }

        a,
        label {
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          // align-items: flex-start;
          background: var(--atalhosBackground);
          padding: 16px 16px;
          text-decoration: none;
        }

        .Wrapper_Card_Icon {
          position: relative;

          i {
            font-size: var(--px-32);
            line-height: var(--px-32px);
          }
        }

        .Wrapper_Card_Title {
          font-family: 'Raleway', sans-serif;
          font-size: var(--px-14);
          line-height: var(--px-18px);
          font-weight: 500;
          color: var(--atalhosBackgroundContrast);
          margin-top: 8px;
        }

        .Wrapper_Card_Content {
          position: relative;
          width: 100%;
        }
      }

      .GreyCard {
        @extend .ListCards_Wrapper_Card;

        &:last-child {
          margin-bottom: 16px;
        }

        a,
        label {
          background: var(--greyCardBackground);
        }
      }
    }
  }
}