.warning-box {
  display: flex;
  align-items: center;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 8px;

  p {
    margin: 0px;
  }

  i {
    margin-right: 16px;
    font-size: 18px;
  }
  background: var(--warningCardBackground);
  color: var(--textContrast);

  @media screen and (min-width: 800px) {
    width: max-content;
    padding: 16px;
  }
}