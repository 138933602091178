/** Ionic CSS Variables **/
:root {
  /** accessibility **/
  --px-0: 0px;
  --px-1: 1px;
  --px-2: 2px;
  --px-3: 3px;
  --px-4: 4px;
  --px-5: 5px;
  --px-6: 6px;
  --px-7: 7px;
  --px-8: 8px;
  --px-9: 9px;
  --px-10: 10px;
  --px-11: 11px;
  --px-12: 12px;
  --px-13: 13px;
  --px-14: 14px;
  --px-15: 15px;
  --px-16: 16px;
  --px-17: 17px;
  --px-18: 18px;
  --px-19: 19px;
  --px-20: 20px;
  --px-21: 21px;
  --px-22: 22px;
  --px-23: 23px;
  --px-24: 24px;
  --px-25: 25px;
  --px-26: 26px;
  --px-28: 28px;
  --px-29: 29px;
  --px-30: 30px;
  --px-32: 32px;
  --px-35: 35px;
  --px-40: 40px;
  --px-48: 48px;
  --px-50: 50px;
  --px-56: 56px;
  --px-59: 59px;
  --px-64: 64px;
  --px-70: 70px;
  --px-72: 72px;
  --px-77: 77px;
  --px-80: 80px;
  --px-90: 90px;
  --px-100: 100px;
  --px-120: 120px;
  --px-290: 290px;
  --px-302: 302px;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
