.Page{
  position: relative;
  padding: var(--px-20) var(--px-0);
  background: var(--pageBackground);
  
  .Page_Header{
      padding: var(--px-0) var(--px-20);
      margin-bottom: 24px;

      .Page_Header_Title{
          display: flex;
          align-items: flex-start;
          position: relative;
          .Header_Title_Icone{
              position: relative;
              margin-right: 6px;

              top: 3px;
              i { 
                  font-size: var(--px-14);
                  line-height: var(--px-15px);
                  color: var(--primary);
              }

              &.__is-bigger {
                i { 
                  font-size: var(--px-20);
                  line-height: var(--px-28px);
                  color: var(--primary);
                } 
              }
          }
          .Header_Title_Text{
              font-family: 'Raleway', sans-serif;
              font-weight: 500;
              position: relative;
              top: 2px;
              width: 100%;
              max-width: calc(100% - 65px);
              z-index: 1;
              font-size: var(--px-20);
              line-height: var(--px-25px);
              color: var(--secondary);
          }
          b{
              font-weight: 700;
          }
          .Header_Title_Actions{
              // position: absolute;
              // top: 0px;
              // right: 0px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 2;
              margin-left: 8px;

            .Title_Actions_Item{
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                cursor: pointer;
                padding: var(--px-8) var(--px-10);
                margin-right: 8px;
                background: var(--secondary);
                &:last-child{
                    margin-right: 0px;
                }
                &:active{
                    outline: none;
                    -webkit-animation: pulse;
                    animation: pulse;
                    -webkit-animation-duration: 0.4s;
                    animation-duration: 0.4s;
                    .Actions_Item_Icone{
                        i{
                           color: var(--headerActionForeground);
                        }
                    }
                }
                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                }
                .Actions_Item_Icone{
                    
                    i{
                        color: var(--secondaryContrast);
                        // @include themifyContrast($themesContrast) {
                        //     color: themedContrast('PageHeaderTitleActionsItemIcon_Color');
                        // }
                        font-size: var(--px-14);
                        line-height: var(--px-14px);
                        transition: color .2s ease-in-out;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                .Actions_Item_Text{
                    font-family: "Raleway", sans-serif;
                    position: relative;
                    top: 0px;
                    font-size: var(--px-12);
                    line-height: var(--px-12px);
                    font-weight: 700;
                    color: var(--secondaryContrast)

                    // @include themifyContrast($themesContrast) {
                    //     color: themedContrast('PageHeaderTitleActionsItemText_Color');
                    // }
                }
                &.Actions_Item_Filtro{
                    overflow: hidden;
                    align-items: unset;
                    padding: var(--px-0) var(--px-0) var(--px-0) var(--px-0);

                    &.__is-highlighted {
                        background: var(--primary);

                      .Actions_Item_Icone {
                        i {
                            color: var(--primaryContrast);
                        }
                      }

                      .Actions_Item_Text {
                        cursor: pointer;
                        padding: 10px 20px !important;
                        transition: background .2s ease-in-out;
                        
                        color: var(--primaryContrast);

                        &:hover{
                          background: var(--primary);
                        }
                      }
                    }

                    .Actions_Item_Icone{
                        padding: 10px 10px 10px 20px;
                        padding-left: 25px;

                        &.Item_Icone_AbreFiltro{
                            display: block;
                        }
                        &.Item_Icone_LimpaFiltro{
                            display: none;
                        }
                    }
                    .Actions_Item_Text{
                        display: flex;
                        align-items: center;
                        padding: var(--px-6) var(--px-10) var(--px-6) var(--px-0);
                        padding-right: 25px;
                    }
                    &.__has-filter{
                        background: var(--primary);
                        // cursor: none;
                        .Actions_Item_Icone{
                            cursor: pointer;
                            border-right: 2px solid #eee;
                            padding: 10px 20px;
                            // margin-right: 8px;
                            transition: background .2s ease-in-out;
                            &:hover{
                                background: var(--primary);
                            }
                            &.Item_Icone_AbreFiltro{
                                display: none;
                            }
                            &.Item_Icone_LimpaFiltro{
                                display: block;
                            }
                            i{
                                color: var(--primaryContrast);
                            }
                        }
                        .Actions_Item_Text{
                            cursor: pointer;
                            padding: 10px 20px !important;
                            transition: background .2s ease-in-out;
                            color: var(--primaryContrast);
                            &:hover{
                                background: var(--primary);
                            }
                        }
                    }
                    &.__is-inactive {
                        cursor: not-allowed;
                        opacity: 60%;
                    }
                }
                @media screen and (max-width: 800px){
                .Title_Actions_Item{
                    .Actions_Item_Text{
                        display: none;
                    }
                    &.Actions_Item_Filtro{
                        .Actions_Item_Text{
                        //   display: block;
                        }
                    }
                }
            }
            }
        }
      }
      .Page_Header_Subtitle{
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
          font-size: var(--px-13);
          line-height: var(--px-15px);
          margin-top: var(--px-4px);
          color: var(--pageSubtitle);
      }
  }
  .Page_Tab{
      position: relative;
      margin-bottom: 20px;

      .Page_Tab_Nav{
          display: flex;
          justify-content: stretch;
          .Tab_Nav_Item{
              font-size: var(--px-14);
              line-height: var(--px-18px);
              background: var(--pageTabNavBackground);
              flex:1;
              font-weight: 400;
              transition: background .2s ease-in-out;
              text-align: center;
              font-family: 'Raleway', sans-serif;
              a{
                  padding: var(--px-20) var(--px-24);
                  color: var(--pageTabNavLink);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-decoration: none;
                  transition: color .2s ease-in-out;
                  height: 100%;
              }
              &.__is-active{
                background: var(--primary);
                  a{
                    color: var(--primaryContrast);
                  }
              }
              &:active{
                  animation: button;
                  animation-duration: .2s;
                  animation-timing-function: ease-in-out;
              }

              &:hover {
                  cursor: pointer;
              }
          }
      }
      .Page_Tab_Content{
          position: relative;
          .Tab_Content_Footer{
              margin-top: 20px;
              padding: var(--px-0) var(--px-20) var(--px-20) var(--px-20);

              .Content_Footer_Item{
                  position: relative;
                  margin-bottom: 14px;
                  display: flex;
                  justify-content: center;
                  &:last-child{
                      margin-bottom: 0px;
                  }
              }
          }
      }
  }

  .Page_Header_BackDesktop {
    margin-right: 16px;
    font-size: var(--px-14);
    line-height: var(--px-14);
    font-weight: 450;
    display: none;
    cursor: pointer;

    @media screen and (min-width: 800px) {
      display: block;
    }

    a {
      display: flex;
      align-items: center;
      width: 100%;
      text-decoration: none;
      color: var(--primary);

      i {
        color: var(--primary);
        font-size: var(--px-24);
        line-height: var(--px-23);
        margin-right: 8px;
      }
    }
  }
}
