/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800&display=swap");
@import "~@ng-select/ng-select/themes/default.theme.css";
@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes button {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes button {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes anim-effect-radomir {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 1);
    transform: scale3d(0.4, 0.4, 1);
  }
  80% {
    box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
    opacity: 0.1;
  }
  100% {
    box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
    opacity: 0;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}
@keyframes anim-effect-radomir {
  0% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 1);
    transform: scale3d(0.4, 0.4, 1);
  }
  80% {
    box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
    opacity: 0.1;
  }
  100% {
    box-shadow: inset 0 0 0 2px rgba(111, 148, 182, 0.8);
    opacity: 0;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1);
  }
}
.Application {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.Header {
  background: var(--headerBackground);
  border-bottom: 1px solid var(--headerBorder);
  box-shadow: 0px 4px 4px -3px var(--headerShadow);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 2;
  padding: var(--px-8) var(--px-8);
  height: var(--px-56);
}
.Header .Header_Voltar {
  margin-right: auto;
  outline: none;
  padding: var(--px-8) var(--px-10);
}
.Header .Header_Voltar i {
  font-size: var(--px-20);
  color: var(--headerBackButton);
  transition: 0.2s ease-in-out color;
}
.Header .Header_Voltar:active {
  outline: none;
  animation: pulse;
  animation-duration: 0.4s;
}
.Header .Header_Voltar:active i {
  color: var(--primary);
}
.Header .Header_Logo {
  max-width: 160px;
  position: absolute;
  top: calc(50% + 1px);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header .Header_Logo img {
  height: 100%;
  max-height: 45px;
}
.Header .Header_Logo .Header_Logo_Desktop {
  display: none;
}
.Header .Header_Logo .Header_Logo_Mobile {
  display: block;
}
.Header .Header_Atalho {
  position: relative;
}
.Header .Header_Atalho .Header_Atalho_Action {
  padding: var(--px-8) var(--px-10);
  cursor: pointer;
}
.Header .Header_Atalho .Header_Atalho_Action i {
  font-size: var(--px-20);
  color: var(--headerAction);
  transition: 0.1s ease-in-out color;
}
.Header .Header_Atalho .Header_Atalho_Action i:hover {
  color: var(--primary);
}
.Header .Header_Atalho .Header_Atalho_Action i.Atalho_Action_FakeIcon {
  padding: 0;
  color: var(--headerAction);
  font-family: "Raleway", "Open Sans", sans-serif;
  font-size: var(--px-20);
  font-weight: 700;
  font-style: normal;
  transition: 0.1s ease-in-out color;
}
.Header .Header_Atalho .Header_Atalho_Action i.Atalho_Action_FakeIcon:hover {
  color: var(--primary);
}
.Header .Header_Atalho .Header_Atalho_Action:active {
  outline: none;
  animation: pulse;
  animation-duration: 0.4s;
}
.Header .Header_Atalho .Header_Atalho_Action:active i {
  color: var(--primary);
}
.Header .Header_Atalho .Header_Atalho_Popup {
  display: none;
  position: absolute;
  top: 60px;
  background: var(--headerPopupBackground);
  border: 1px solid var(--headerPopupBorder);
  width: 400px;
}
.Header .Header_Atalho .Header_Atalho_Popup.__is-left {
  right: -16px;
}
.Header .Header_Atalho .Header_Atalho_Popup.__is-left:after {
  content: " ";
  display: block;
  position: absolute;
  top: -21px;
  right: 12px;
  width: 0;
  height: 0;
  border-left: 21px solid var(--headerPopupBackground);
  border-right: 21px solid var(--headerPopupBackground);
  border-bottom: 21px solid var(--headerPopupBorder);
  z-index: 1;
}
.Header .Header_Atalho .Header_Atalho_Popup.__is-left:before {
  content: " ";
  display: block;
  position: absolute;
  top: -20px;
  right: 13px;
  width: 0;
  height: 0;
  border-left: 20px solid var(--headerPopupBackground);
  border-right: 20px solid var(--headerPopupBackground);
  background: var(--headerPopupBackground);
  border-bottom: 20px solid var(--headerPopupBorder);
  z-index: 2;
}
.Header .Header_Atalho .Header_Atalho_Popup.__is-right {
  left: -16px;
}
.Header .Header_Atalho .Header_Atalho_Popup.__is-right:after {
  content: " ";
  display: block;
  position: absolute;
  top: -21px;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 21px solid var(--headerPopupBackground);
  border-right: 21px solid var(--headerPopupBackground);
  background: var(--headerPopupBackground);
  border-bottom: 21px solid var(--headerPopupBorder);
  z-index: 1;
}
.Header .Header_Atalho .Header_Atalho_Popup.__is-right:before {
  content: " ";
  display: block;
  position: absolute;
  background: var(--headerPopupBackground);
  top: -20px;
  left: 13px;
  width: 0;
  height: 0;
  border-left: 20px solid var(--headerPopupBackground);
  background: var(--headerPopupBackground);
  border-right: 20px solid var(--headerPopupBackground);
  border-bottom: 20px solid var(--headerPopupBorder);
  z-index: 2;
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Header {
  width: 100%;
  padding: var(--px-24) var(--px-24) var(--px-16) var(--px-24);
  font-size: var(--px-20);
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Header .Popup_Header_Title {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: var(--secondary);
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Header .Popup_Header_Subtitle {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: var(--px-13);
  margin-top: var(--px-4);
  color: var(--headerPopupSubtitle);
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Close {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--px-40);
  height: var(--px-40);
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Close:active {
  outline: none;
  animation: pulse;
  animation-duration: 0.4s;
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Close i {
  font-size: var(--px-16);
  color: var(--headerPopupCloseIcon);
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Close i:hover {
  cursor: pointer;
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Loading {
  width: 100%;
  height: 100%;
  background: var(--headerPopupLoadingBackground);
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 9;
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Loading.__is-active {
  display: flex;
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Loading .Popup_Loading_Icon {
  text-align: center;
  background-image: url("/assets/svg/loading.svg");
  background-position: left center;
  width: 70px;
  height: 60px;
  display: inline-block;
  background-size: 165%;
  background-repeat: no-repeat;
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Loading .Popup_Loading_Msg {
  font-family: "Raleway", sans-serif;
  font-size: var(--px-14);
  font-weight: 700;
  color: var(--secondary);
  line-height: var(--px-21);
  margin-top: 8px;
  text-align: center;
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Content {
  position: relative;
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Content::-webkit-scrollbar-track {
  background-color: var(--headerPopupScrollTrackBackground);
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Content::-webkit-scrollbar {
  width: 4px;
  background-color: var(--headerPopupScrollBarBackground);
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Content::-webkit-scrollbar-thumb {
  background-color: var(--headerPopupScrollThumbBackground);
  border: 0px solid var(--headerPopupScrollThumbBorder);
}
.Header .Header_Atalho .Header_Atalho_Popup .Atalho_Popup_Content.__has-padding {
  padding: var(--px-16) var(--px-24);
}
.Header .Header_Atalho.__is-open .Header_Atalho_Popup {
  display: block;
}
.Header .Header_Atalho.Header_Atalho_Notificacoes .Header_Atalho_Popup .Atalho_Popup_Content {
  max-height: 530px;
  overflow: auto;
}
.Header .Header_Atalho.Header_Atalho_Notificacoes .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Item .Header_ClickableNotificationLink .ListActions_Item_Ico i {
  color: var(--primary);
}
.Header .Header_Atalho.Header_Atalho_Notificacoes .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Item .Header_ClickableNotificationLink .ListActions_Item_Ico.__is-new {
  position: relative;
}
.Header .Header_Atalho.Header_Atalho_Notificacoes .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Item .Header_ClickableNotificationLink .ListActions_Item_Ico.__is-new .Item_Ico_Contador {
  position: absolute;
  top: -3px;
  right: -2px;
  height: 12px;
  width: 11px;
  border-radius: 50%;
  color: var(--primaryContrast);
  background: var(--danger);
}
.Header .Header_Atalho.Header_Atalho_Notificacoes .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Separator {
  font-family: "Raleway";
  font-size: var(--px-13);
  font-weight: 600;
  margin: 0 20px;
  color: var(--primary);
}
.Header .Header_Atalho.Header_Atalho_Notificacoes .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Item + .ListActions_Separator {
  margin-top: 20px;
}
.Header .Header_Atalho.Header_Atalho_Notificacoes .Header_Atalho_Action {
  position: relative;
}
.Header .Header_Atalho.Header_Atalho_Notificacoes .Header_Atalho_Action .Header_Notificacoes_Contador {
  position: absolute;
  top: -2px;
  right: -4px;
  font-size: var(--px-11);
  line-height: var(--px-11);
  height: 23px;
  width: 23px;
  font-weight: 800;
  background: var(--primary);
  color: var(--primaryContrast);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action:hover .Atalho_Configuracoes_Icon i {
  color: var(--primary);
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action:hover .Atalho_Configuracoes_Arrow i {
  color: var(--headerAction);
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action .Atalho_Configuracoes_Icon i {
  font-size: var(--px-18);
  color: var(--headerAction);
  transition: 0.2s ease-in;
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action .Atalho_Configuracoes_Name {
  display: none;
  margin-left: 12px;
  font-family: "Raleway", sans-serif;
  font-size: var(--px-16);
  line-height: var(--px-16);
  font-weight: 700;
  color: var(--headerAction);
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action .Atalho_Configuracoes_Arrow {
  display: none;
  margin-left: 8px;
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action .Atalho_Configuracoes_Arrow i {
  font-size: var(--px-18);
  color: var(--headerAction);
  transition: 0.2s ease-in;
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Item label {
  cursor: pointer;
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Item.ListActions_Item_Logout {
  background: var(--danger);
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Item.ListActions_Item_Logout .ListActions_Item_Ico i,
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Item.ListActions_Item_Logout .ListActions_Item_Ico span {
  color: var(--dangerContrast);
}
.Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Popup .Atalho_Popup_Content .ListActions .ListActions_Item.ListActions_Item_Logout .ListActions_Item_Label {
  color: var(--dangerContrast);
  font-weight: 700;
  font-size: var(--px-16);
}
.Header .Header_Atalho.Header_Atalho_CentralAtendimento .Header_Atalho_Popup {
  width: 580px;
}
.Header .Header_Atalho.Header_Atalho_CentralAtendimento .Header_Atalho_Popup .Atalho_Popup_Content {
  padding: 8px 24px 24px 24px;
}
.Header .Header_Atalho.Header_Atalho_CentralAtendimento .Header_Atalho_Popup.__is-sindico {
  width: 840px;
}
.Header .Header_Atalho.Header_Shortcut {
  display: none;
}
@media screen and (min-width: 800px) {
  .Header .Header_Atalho.Header_Shortcut {
    display: block;
  }
}
.Header .Header_Atalho.Header_Shortcut .Header_Shortcut_Popup .Shortcut_Popup_Content {
  max-height: 521px;
  overflow: auto;
}
.Header .Header_Atalho.Header_Atalho_Duvidas, .Header .Header_Atalho.Header_Atalho_CentralAtendimento, .Header .Header_Atalho.Header_Atalho_Notificacoes, .Header .Header_Atalho.Header_Atalho_PerguntasRespostas, .Header .Header_Atalho.Header_Atalho_Acessibilidade, .Header .Header_Atalho.Header_Atalho_Seguros {
  display: none;
}
.Header .Header_Search {
  margin-left: auto;
  display: none;
}
.Header .Header_Search .Header_Search_Input {
  margin: 0 16px;
  position: relative;
}
.Header .Header_Search .Header_Search_Input .Search_Input_IconSearch {
  position: absolute;
  top: 0;
  left: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  z-index: 2;
  justify-content: center;
  align-items: center;
}
.Header .Header_Search .Header_Search_Input .Search_Input_IconSearch i {
  font-size: var(--px-14);
  color: var(--grayFading);
}
.Header .Header_Search .Header_Search_Input .Search_Input_IconClean {
  position: absolute;
  top: 0;
  right: 0px;
  width: 32px;
  height: 40px;
  display: flex;
  z-index: 2;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.Header .Header_Search .Header_Search_Input .Search_Input_IconClean i {
  font-size: var(--px-14);
  color: 666;
}
.Header .Header_Search .Header_Search_Input input {
  padding: 12px 12px 12px 40px;
  font-family: "Raleway", sans-serif;
  font-size: var(--px-14);
  font-weight: 400;
  color: #666;
  background: #c9c9c9;
  border: none;
  border-radius: 6px;
  width: 100%;
  z-index: 1;
  position: relative;
  transition: background 0.2s ease-in-out, padding 0.2s ease-in-out;
}
.Header .Header_Search .Header_Search_Input input::placeholder {
  color: #C9C9C9;
}
.Header .Header_Search .Header_Search_Input input[type=text]:focus {
  padding: 12px 32px 12px 40px;
}
.Header .Header_Search .Header_Search_Input input[type=text]:focus ~ .Search_Input_IconClean {
  opacity: 1;
}
@media screen and (min-width: 440px) {
  .Header {
    position: absolute;
  }
}
@media screen and (max-width: 799px) {
  .Header .Header_Atalho .Header_Atalho_Popup {
    display: none !important;
  }
}
@media screen and (min-width: 800px) {
  .Header {
    justify-content: flex-start;
    padding: var(--px-16) var(--px-24);
    height: var(--px-72);
  }
  .Header .Header_Logo {
    top: auto;
    left: auto;
    position: relative;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    min-width: 73px;
    max-width: none;
  }
  .Header .Header_Logo img {
    height: 100%;
    max-height: 60px;
    width: 100%;
  }
  .Header .Header_Voltar {
    display: none;
  }
  .Header .Header_Search {
    display: block;
  }
  .Header .Header_Atalho.Header_Atalho_Person {
    display: none;
  }
  .Header .Header_Atalho.Header_Atalho_Perfil {
    margin-left: 6%;
    min-width: 170px;
    margin-right: auto;
  }
  .Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action {
    max-width: max-content;
  }
  .Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action .Atalho_Configuracoes_Icon {
    position: relative;
  }
  .Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action .Atalho_Configuracoes_Icon i {
    color: var(--primary);
  }
  .Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action .Atalho_Configuracoes_Name {
    display: block;
  }
  .Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action .Atalho_Configuracoes_Arrow {
    display: block;
  }
  .Header .Header_Atalho.Header_Atalho_Duvidas, .Header .Header_Atalho.Header_Atalho_CentralAtendimento, .Header .Header_Atalho.Header_Atalho_Notificacoes, .Header .Header_Atalho.Header_Atalho_Acessibilidade, .Header .Header_Atalho.Header_Atalho_PerguntasRespostas, .Header .Header_Atalho.Header_Atalho_Perfil, .Header .Header_Atalho.Header_Atalho_Seguros {
    display: block;
  }
}
@media screen and (min-width: 800px) and (max-width: 950px) {
  .Header .Header_Atalho.Header_Atalho_Perfil {
    margin-left: 6%;
    min-width: auto;
  }
  .Header .Header_Atalho.Header_Atalho_Perfil .Header_Atalho_Action .Atalho_Configuracoes_Name {
    display: none;
  }
}
.Header .EmailAdvice {
  position: absolute;
  bottom: 0px;
  left: 23px;
}
.Header .EmailAdvice i {
  font-size: var(--px-14) !important;
  color: var(--danger) !important;
}
@media screen and (min-width: 800px) {
  .Header .EmailAdvice i {
    font-size: var(--px-16) !important;
  }
}
.Header .Atalho_Configuracoes_Icon {
  position: relative;
}
.Header .ListActions_Item {
  cursor: pointer;
}
.Header .Header_Atalho_Action {
  position: relative;
}
.Header .Header_Atalho_Action i:hover i {
  -webkit-text-stroke-color: var(--primary);
}
.Header .overlayed-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-text-stroke-width: 0.7px;
  right: 3px;
  bottom: 3px;
  -webkit-text-stroke-color: var(--text);
}
.Header .overlayed-icon i {
  font-size: 16px !important;
  color: var(--headerBackground) !important;
}
.Header .overlayed-icon:hover i {
  -webkit-text-stroke-color: var(--primary);
}

.TabNav {
  width: 100%;
  padding: var(--px-0) var(--px-10);
  max-height: var(--px-59);
  height: 100%;
  margin-top: auto;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.15);
  background: var(--pageTabNavBackground);
  transition: opacity 0.3s ease-in-out, bottom 0.4s ease-in-out;
}
.TabNav .TabNav_List {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.TabNav .TabNav_List .TabNav_List_Item {
  position: relative;
  flex: 1;
}
.TabNav .TabNav_List .TabNav_List_Item .List_Item_NewNotificationIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: -2px;
  right: -4px;
  line-height: var(--px-11);
  height: 15px;
  width: 15px;
  background: var(--primary);
  border-radius: 50%;
}
.TabNav .TabNav_List .TabNav_List_Item:active a .List_Item_Icon i, .TabNav .TabNav_List .TabNav_List_Item.__is-active a .List_Item_Icon i {
  color: var(--primary);
}
.TabNav .TabNav_List .TabNav_List_Item:active a .List_Item_Icon i.Item_Icon_Fake, .TabNav .TabNav_List .TabNav_List_Item.__is-active a .List_Item_Icon i.Item_Icon_Fake {
  color: var(--primary);
}
.TabNav .TabNav_List .TabNav_List_Item:active a .List_Item_Label, .TabNav .TabNav_List .TabNav_List_Item.__is-active a .List_Item_Label {
  color: var(--primary);
}
.TabNav .TabNav_List .TabNav_List_Item:active {
  animation: pulse;
  animation-duration: 0.2s;
}
.TabNav .TabNav_List .TabNav_List_Item a {
  padding: var(--px-10) var(--px-10);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 10px;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
.TabNav .TabNav_List .TabNav_List_Item a .List_Item_Icon {
  position: relative;
}
.TabNav .TabNav_List .TabNav_List_Item a .List_Item_Icon i {
  font-size: var(--px-20);
  line-height: var(--px-20px);
  color: var(--text);
  transition: 0.1s ease-in-out color;
}
.TabNav .TabNav_List .TabNav_List_Item a .List_Item_Icon i.Item_Icon_Fake {
  color: var(--text);
  padding: 0;
  font-family: "Raleway", "Open Sans", sans-serif;
  font-size: var(--px-20);
  font-weight: 700;
  font-style: normal;
  transition: 0.1s ease-in-out color;
}
.TabNav .TabNav_List .TabNav_List_Item a .List_Item_Label {
  font-size: var(--px-10);
  line-height: var(--px-10);
  margin-top: var(--px-4);
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  transition: 0.1s ease-in-out color;
  color: var(--text);
}
.TabNav.__has-banner .TabNav_Banner {
  display: flex;
}
.TabNav .TabNav_Banner {
  height: var(--px-77);
  width: 100vw;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  left: -10px;
}
@media screen and (min-width: 800px) {
  .TabNav {
    opacity: 0;
    bottom: -200%;
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .TabNav {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .TabNav {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .TabNav {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.Modal {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  top: -150%;
  left: 0px;
  transition: top 0.3s 0.3s ease-in-out;
  background: var(--modalBackground);
  box-shadow: 17px 17px -2px rgba(0, 0, 0, 0.08);
  padding: 0;
  display: flex;
  flex-direction: column;
}
.Modal.__is-open {
  top: 0px;
}
.Modal .Modal_Close {
  width: 48px;
  height: var(--px-48);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
}
.Modal .Modal_Close i {
  font-size: var(--px-24);
  color: var(--modalClose);
}
.Modal .Modal_Close:active {
  outline: none;
  -webkit-animation: pulse;
  animation: pulse;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}
.Modal .Modal_Close:hover {
  cursor: pointer;
}
.Modal .Modal_Header {
  font-family: "Raleway", "Open Sans", sans-serif;
  margin-bottom: auto;
  text-align: center;
  padding: var(--px-32) var(--px-40) var(--px-16) var(--px-32);
  font-size: var(--px-20);
  font-weight: 600;
  color: var(--title);
}
.Modal .Modal_Content {
  padding: 0px 0px;
  height: 100%;
  overflow: auto;
}
.Modal .Modal_Content .Block {
  border: none;
  box-shadow: none;
  margin-bottom: 0px;
}
.Modal .Modal_Content::-webkit-scrollbar-track {
  background-color: var(--modalScrollTrackBackground);
}
.Modal .Modal_Content::-webkit-scrollbar {
  width: var(--px-4);
  background-color: var(--modalScrollBarBackground);
}
.Modal .Modal_Content::-webkit-scrollbar-thumb {
  background-color: var(--modalScrollThumbBackground);
  border: var(--modalScrollThumbBorder);
}
.Modal .Modal_Footer {
  padding: var(--px-16) var(--px-16) var(--px-16) var(--px-16);
  margin-top: auto;
}
.Modal .Modal_Footer .Modal_Footer_ListActions {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.Modal .Modal_Footer .Modal_Footer_ListActions .Footer_ListActions_Item {
  padding: var(--px-4) var(--px-4);
  min-width: max-content;
}
.Modal .Modal_Footer .Modal_Footer_ListActions .Footer_ListActions_Item.__is-full-width {
  width: 100%;
}

.Container {
  padding: 0px 0px;
  max-height: calc(100% - var(--px-56) - var(--px-59));
  margin-top: var(--px-56);
  margin-bottom: var(--px-59);
  overflow: auto;
  position: relative;
  z-index: 1;
  height: 100%;
  background: var(--pageBackground);
  /* Essa classe deve ser adicionada ao .Container quando for adicionada ao tabnav*/
}
.Container.__has-banner {
  max-height: calc(100vh - var(--px-56) - var(--px-59) - var(--px-77));
}
.Container.__has-modal {
  z-index: 999999999;
  position: relative;
  max-height: 100%;
  margin: 0;
}
@media screen and (min-width: 800px) {
  .Container {
    max-height: calc(100% - var(--px-72));
    margin-top: var(--px-72);
    margin-bottom: 0px;
  }
}

.Block {
  box-shadow: 0 7px 7px -2px rgba(0, 0, 0, 0.08);
  border-top: 1px solid var(--blockBorderTop);
  margin-bottom: 20px;
}
.Block .Block_Title {
  font-family: "Raleway", sans-serif;
  font-size: var(--px-13);
  font-weight: 700;
  color: var(--primary);
  line-height: var(--px-15px);
  padding: var(--px-20) var(--px-20) var(--px-0) var(--px-20);
}
.Block .Block_Title:last-child {
  margin-bottom: 0px;
}
.Block .Block_Content {
  position: relative;
  margin-top: 16px;
}
.Block .Block_Container {
  position: relative;
  padding: var(--px-20) var(--px-20) var(--px-20) var(--px-20);
}
.Block .Block_Footer {
  padding: var(--px-0) var(--px-20) var(--px-20) var(--px-20);
  margin-top: 20px;
}
.Block .Block_Footer .Block_Footer_Item {
  position: relative;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
}
.Block .Block_Footer .Block_Footer_Item:last-child {
  margin-bottom: 0px;
}

.ListActions,
.InsideListAction {
  width: 100%;
}
.ListActions .ListActions_Item,
.InsideListAction .ListActions_Item {
  position: relative;
  border-bottom: 1px solid var(--listActionBorderBottom);
}
.ListActions .ListActions_Item:last-child,
.InsideListAction .ListActions_Item:last-child {
  border-bottom: 0;
}
.ListActions .ListActions_Item a,
.ListActions .ListActions_Item label,
.InsideListAction .ListActions_Item a,
.InsideListAction .ListActions_Item label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  width: 100%;
  padding: var(--px-16) var(--px-20);
}
.ListActions .ListActions_Item a:active .ListActions_Item_Action i,
.ListActions .ListActions_Item label:active .ListActions_Item_Action i,
.InsideListAction .ListActions_Item a:active .ListActions_Item_Action i,
.InsideListAction .ListActions_Item label:active .ListActions_Item_Action i {
  animation: pulse;
  animation-duration: 0.2s;
  color: var(--listActionItemIcon);
}
.ListActions .ListActions_Item a .ListActions_Item_Ico,
.ListActions .ListActions_Item label .ListActions_Item_Ico,
.InsideListAction .ListActions_Item a .ListActions_Item_Ico,
.InsideListAction .ListActions_Item label .ListActions_Item_Ico {
  position: relative;
  margin-right: 10px;
}
.ListActions .ListActions_Item a .ListActions_Item_Ico i,
.ListActions .ListActions_Item a .ListActions_Item_Ico span,
.ListActions .ListActions_Item label .ListActions_Item_Ico i,
.ListActions .ListActions_Item label .ListActions_Item_Ico span,
.InsideListAction .ListActions_Item a .ListActions_Item_Ico i,
.InsideListAction .ListActions_Item a .ListActions_Item_Ico span,
.InsideListAction .ListActions_Item label .ListActions_Item_Ico i,
.InsideListAction .ListActions_Item label .ListActions_Item_Ico span {
  color: var(--listActionItemIcon);
  transition: 0.2s ease-in-out;
  font-size: var(--px-20);
  line-height: var(--px-20px);
}
.ListActions .ListActions_Item a .ListActions_Item_Label,
.ListActions .ListActions_Item label .ListActions_Item_Label,
.InsideListAction .ListActions_Item a .ListActions_Item_Label,
.InsideListAction .ListActions_Item label .ListActions_Item_Label {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: var(--px-13);
  line-height: var(--px-18px);
  color: var(--listActionItemLabel);
}
.ListActions .ListActions_Item a .ListActions_Item_Action,
.ListActions .ListActions_Item label .ListActions_Item_Action,
.InsideListAction .ListActions_Item a .ListActions_Item_Action,
.InsideListAction .ListActions_Item label .ListActions_Item_Action {
  margin-left: auto;
}
.ListActions .ListActions_Item a .ListActions_Item_Action i,
.ListActions .ListActions_Item label .ListActions_Item_Action i,
.InsideListAction .ListActions_Item a .ListActions_Item_Action i,
.InsideListAction .ListActions_Item label .ListActions_Item_Action i {
  font-size: var(--px-14);
  line-height: var(--px-14px);
  color: var(--listActionItemIcon);
}

.InsideListAction {
  width: 75%;
  margin: 45px auto;
  border-bottom: none;
}
.InsideListAction .ListActions_ItemInside {
  border-bottom: none;
  position: relative;
  border-bottom: 1px solid var(--listActionBorderBottom);
}
.InsideListAction .ListActions_ItemInside:last-child {
  border-bottom: 0;
}
.InsideListAction .ListActions_ItemInside a,
.InsideListAction .ListActions_ItemInside label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  width: 100%;
  padding: 0;
}
.InsideListAction .ListActions_ItemInside a:active .ListActions_Item_Action i,
.InsideListAction .ListActions_ItemInside label:active .ListActions_Item_Action i {
  animation: pulse;
  animation-duration: 0.2s;
  color: var(--listActionItemIcon);
}
.InsideListAction .ListActions_ItemInside a .ListActions_Item_Ico,
.InsideListAction .ListActions_ItemInside label .ListActions_Item_Ico {
  position: relative;
  margin-right: 10px;
}
.InsideListAction .ListActions_ItemInside a .ListActions_Item_Ico i,
.InsideListAction .ListActions_ItemInside a .ListActions_Item_Ico span,
.InsideListAction .ListActions_ItemInside label .ListActions_Item_Ico i,
.InsideListAction .ListActions_ItemInside label .ListActions_Item_Ico span {
  color: var(--listActionItemIcon);
  transition: 0.2s ease-in-out;
  font-size: var(--px-20);
  line-height: var(--px-20px);
}
.InsideListAction .ListActions_ItemInside a .ListActions_Item_Label,
.InsideListAction .ListActions_ItemInside label .ListActions_Item_Label {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: var(--px-13);
  line-height: var(--px-18px);
  color: var(--listActionItemLabel);
}
.InsideListAction .ListActions_ItemInside a .ListActions_Item_Action,
.InsideListAction .ListActions_ItemInside label .ListActions_Item_Action {
  margin-left: auto;
}
.InsideListAction .ListActions_ItemInside a .ListActions_Item_Action i,
.InsideListAction .ListActions_ItemInside label .ListActions_Item_Action i {
  font-size: var(--px-14);
  line-height: var(--px-14px);
  color: var(--listActionItemIcon);
}

.SubHeader {
  display: flex;
  justify-content: space-between;
  background-color: var(--tertiary);
  color: var(--tertiaryContrast);
  padding: 18px 34px;
}

.ListCards {
  width: 100%;
  padding-bottom: 16px;
}
@supports (display: grid) {
  .ListCards.__is-grid .ListCards_Wrapper {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard {
    width: 100%;
    flex-shrink: 0;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card:last-child, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard:last-child, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard:last-child {
    margin-right: 0px;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card a, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard a, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard a,
.ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card label,
.ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard label,
.ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard label {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--atalhosBackground);
    padding: 16px 16px;
    text-decoration: none;
    height: 100%;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon {
    position: relative;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon i, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon i, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon i {
    font-size: var(--px-32);
    line-height: var(--px-32px);
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Title, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Title, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard .Wrapper_Card_Title {
    font-family: "Raleway", sans-serif;
    font-size: var(--px-14);
    line-height: var(--px-18px);
    color: var(--atalhosBackgroundContrast);
    font-weight: 500;
    margin-top: 8px;
  }
}
@supports (not (display: grid)) and (display: flex) {
  .ListCards.__is-grid .ListCards_Wrapper {
    padding: 0 20px;
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard {
    width: calc(50% - 10px);
    margin: 0px 5px 10px 5px;
    flex-shrink: 0;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card:last-child, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard:last-child, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard:last-child {
    margin-right: 0px;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card a, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard a, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard a,
.ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card label,
.ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard label,
.ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard label {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--atalhosBackground);
    padding: 16px 16px;
    text-decoration: none;
    height: 100%;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon {
    position: relative;
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon i, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon i, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon i {
    font-size: var(--px-32);
    line-height: var(--px-32px);
  }
  .ListCards.__is-grid .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Title, .ListCards.__is-grid .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Title, .ListCards.__is-full-width .ListCards.__is-grid .ListCards_Wrapper .GreyCard .Wrapper_Card_Title {
    font-family: "Raleway", sans-serif;
    font-size: var(--px-14);
    line-height: var(--px-18px);
    font-weight: 500;
    color: var(--atalhosBackgroundContrast);
    margin-top: 8px;
  }
}
.ListCards.__is-swipe {
  display: flex;
  overflow-x: scroll;
}
.ListCards.__is-swipe .ListCards_Wrapper {
  display: flex;
  padding: 0 20px;
  width: 100%;
}
.ListCards.__is-swipe .ListCards_Wrapper .ListCards_Wrapper_Card, .ListCards.__is-swipe .ListCards.__is-full-width .ListCards_Wrapper .GreyCard, .ListCards.__is-full-width .ListCards.__is-swipe .ListCards_Wrapper .GreyCard {
  min-width: 40%;
  max-width: 40vw;
  margin: 0px 10px 10px 0;
  flex-shrink: 0;
}
.ListCards.__is-swipe .ListCards_Wrapper .ListCards_Wrapper_Card:last-child, .ListCards.__is-swipe .ListCards.__is-full-width .ListCards_Wrapper .GreyCard:last-child, .ListCards.__is-full-width .ListCards.__is-swipe .ListCards_Wrapper .GreyCard:last-child {
  margin-right: 0px;
  padding-right: 10px;
  min-width: calc(40% + 10px);
}
.ListCards.__is-swipe .ListCards_Wrapper .ListCards_Wrapper_Card a, .ListCards.__is-swipe .ListCards.__is-full-width .ListCards_Wrapper .GreyCard a, .ListCards.__is-full-width .ListCards.__is-swipe .ListCards_Wrapper .GreyCard a,
.ListCards.__is-swipe .ListCards_Wrapper .ListCards_Wrapper_Card label,
.ListCards.__is-swipe .ListCards.__is-full-width .ListCards_Wrapper .GreyCard label,
.ListCards.__is-full-width .ListCards.__is-swipe .ListCards_Wrapper .GreyCard label {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: var(--atalhosBackground);
  padding: 16px 16px;
  text-decoration: none;
}
.ListCards.__is-swipe .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon, .ListCards.__is-swipe .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon, .ListCards.__is-full-width .ListCards.__is-swipe .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon {
  position: relative;
}
.ListCards.__is-swipe .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon i, .ListCards.__is-swipe .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon i, .ListCards.__is-full-width .ListCards.__is-swipe .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon i {
  font-size: var(--px-32);
  line-height: var(--px-32px);
}
.ListCards.__is-swipe .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Title, .ListCards.__is-swipe .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Title, .ListCards.__is-full-width .ListCards.__is-swipe .ListCards_Wrapper .GreyCard .Wrapper_Card_Title {
  font-family: "Raleway", sans-serif;
  font-size: var(--px-14);
  line-height: var(--px-18px);
  font-weight: 500;
  color: var(--atalhosBackgroundContrast);
  margin-top: 8px;
}
.ListCards.__is-floated .ListCards_Wrapper {
  padding: 0 20px;
  column-count: 2;
  column-gap: 10px;
}
.ListCards.__is-floated .ListCards_Wrapper .ListCards_Wrapper_Card, .ListCards.__is-floated .ListCards.__is-full-width .ListCards_Wrapper .GreyCard, .ListCards.__is-full-width .ListCards.__is-floated .ListCards_Wrapper .GreyCard {
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
}
.ListCards.__is-floated .ListCards_Wrapper .ListCards_Wrapper_Card a, .ListCards.__is-floated .ListCards.__is-full-width .ListCards_Wrapper .GreyCard a, .ListCards.__is-full-width .ListCards.__is-floated .ListCards_Wrapper .GreyCard a,
.ListCards.__is-floated .ListCards_Wrapper .ListCards_Wrapper_Card label,
.ListCards.__is-floated .ListCards.__is-full-width .ListCards_Wrapper .GreyCard label,
.ListCards.__is-full-width .ListCards.__is-floated .ListCards_Wrapper .GreyCard label {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: var(--atalhosBackground);
  padding: 16px 16px;
  text-decoration: none;
  color: inherit;
}
.ListCards.__is-floated .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon, .ListCards.__is-floated .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon, .ListCards.__is-full-width .ListCards.__is-floated .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon {
  position: relative;
}
.ListCards.__is-floated .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon i, .ListCards.__is-floated .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon i, .ListCards.__is-full-width .ListCards.__is-floated .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon i {
  font-size: var(--px-32);
  line-height: var(--px-32px);
}
.ListCards.__is-floated .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Title, .ListCards.__is-floated .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Title, .ListCards.__is-full-width .ListCards.__is-floated .ListCards_Wrapper .GreyCard .Wrapper_Card_Title {
  font-family: "Raleway", sans-serif;
  font-size: var(--px-14);
  line-height: var(--px-18px);
  font-weight: 500;
  color: var(--atalhosBackgroundContrast);
  margin-top: 8px;
}
.ListCards.__is-full-width {
  display: flex;
  overflow-x: auto;
}
.ListCards.__is-full-width .ListCards_Wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
}
.ListCards.__is-full-width .ListCards_Wrapper .ListCards_Wrapper_Card, .ListCards.__is-full-width .ListCards_Wrapper .GreyCard {
  width: 100%;
  margin: 0 0 16px 0;
}
.ListCards.__is-full-width .ListCards_Wrapper .ListCards_Wrapper_Card:last-child, .ListCards.__is-full-width .ListCards_Wrapper .GreyCard:last-child {
  margin-bottom: 0px;
}
.ListCards.__is-full-width .ListCards_Wrapper .ListCards_Wrapper_Card a, .ListCards.__is-full-width .ListCards_Wrapper .GreyCard a,
.ListCards.__is-full-width .ListCards_Wrapper .ListCards_Wrapper_Card label,
.ListCards.__is-full-width .ListCards_Wrapper .GreyCard label {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background: var(--atalhosBackground);
  padding: 16px 16px;
  text-decoration: none;
}
.ListCards.__is-full-width .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon, .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon {
  position: relative;
}
.ListCards.__is-full-width .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Icon i, .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Icon i {
  font-size: var(--px-32);
  line-height: var(--px-32px);
}
.ListCards.__is-full-width .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Title, .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Title {
  font-family: "Raleway", sans-serif;
  font-size: var(--px-14);
  line-height: var(--px-18px);
  font-weight: 500;
  color: var(--atalhosBackgroundContrast);
  margin-top: 8px;
}
.ListCards.__is-full-width .ListCards_Wrapper .ListCards_Wrapper_Card .Wrapper_Card_Content, .ListCards.__is-full-width .ListCards_Wrapper .GreyCard .Wrapper_Card_Content {
  position: relative;
  width: 100%;
}
.ListCards.__is-full-width .ListCards_Wrapper .GreyCard:last-child {
  margin-bottom: 16px;
}
.ListCards.__is-full-width .ListCards_Wrapper .GreyCard a,
.ListCards.__is-full-width .ListCards_Wrapper .GreyCard label {
  background: var(--greyCardBackground);
}

.ListSelect {
  position: relative;
}
.ListSelect .ListSelect_Content {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -5px;
}
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item {
  margin-bottom: 12px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item:last-child {
  margin-bottom: 0px;
}
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item.__is-selected a,
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item.__is-selected label {
  background: var(--primary);
}
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item.__is-selected a .Content_Item_Label,
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item.__is-selected label .Content_Item_Label {
  color: var(--primaryContrast);
}
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item a,
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item label {
  padding: var(--px-12) var(--px-16);
  cursor: pointer;
  background: var(--lightGray);
  border: none;
  text-decoration: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  transition: 0.2s ease-in-out;
}
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item a .Content_Item_Label,
.ListSelect .ListSelect_Content.__is-full-list .ListSelect_Content_Item label .Content_Item_Label {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: var(--lightGrayContrast);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  font-size: var(--px-14);
  line-height: var(--px-14px);
}
.ListSelect .ListSelect_Content.__is-grid-list .ListSelect_Content_Item {
  width: calc(33.3333333333% - 10px);
  margin: 0 5px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}
.ListSelect .ListSelect_Content.__is-grid-list .ListSelect_Content_Item.__is-selected a,
.ListSelect .ListSelect_Content.__is-grid-list .ListSelect_Content_Item.__is-selected label {
  background: var(--primary);
}
.ListSelect .ListSelect_Content.__is-grid-list .ListSelect_Content_Item.__is-selected a .Content_Item_Label,
.ListSelect .ListSelect_Content.__is-grid-list .ListSelect_Content_Item.__is-selected label .Content_Item_Label {
  color: var(--primaryContrast);
}
.ListSelect .ListSelect_Content.__is-grid-list .ListSelect_Content_Item a,
.ListSelect .ListSelect_Content.__is-grid-list .ListSelect_Content_Item label {
  padding: var(--px-12) var(--px-12);
  cursor: pointer;
  background: var(--lightGray);
  border: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-radius: 6px;
  transition: 0.2s ease-in-out;
  height: 100%;
}
.ListSelect .ListSelect_Content.__is-grid-list .ListSelect_Content_Item a .Content_Item_Label,
.ListSelect .ListSelect_Content.__is-grid-list .ListSelect_Content_Item label .Content_Item_Label {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: var(--lightGrayContrast);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  font-size: var(--px-14);
  line-height: var(--px-14px);
}

.ListEmpty {
  padding: 24px 24px;
  width: 100%;
}
.ListEmpty .ListEmpty_Icon {
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}
.ListEmpty .ListEmpty_Icon i {
  font-size: var(--px-40);
  line-height: var(--px-40);
  color: #aaa;
}
.ListEmpty .ListEmpty_Text {
  font-size: var(--px-18);
  line-height: var(--px-18);
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: #aaa;
  text-align: center;
  width: 100%;
}

.SelectBox {
  background: #ddd;
}
.SelectBox .SelectBox_Actual {
  color: #666;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  font-size: var(--px-13);
  padding: var(--px-20) var(--px-20);
}
.SelectBox .SelectBox_Actual i {
  font-size: var(--px-13);
  right: 20px;
  position: absolute;
  top: calc(50% - 7px);
  color: #666;
}
.SelectBox:hover .SelectBox_List {
  max-height: 250px;
  overflow-y: auto;
  max-height: 250px;
}
.SelectBox:hover .SelectBox_List .SelectBox_List_Content {
  max-height: 100%;
  transform: scaleY(1);
}
.SelectBox .SelectBox_List {
  padding: 0 0px;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  max-height: 0px;
}
.SelectBox .SelectBox_List.__is-open {
  max-height: 250px;
  overflow-y: auto;
}
.SelectBox .SelectBox_List.__is-open .SelectBox_List_Content {
  max-height: 100%;
  transform: scaleY(1);
}
.SelectBox .SelectBox_List .SelectBox_List_Content {
  position: relative;
  display: block;
  max-height: 0px;
  transition: 0.2s ease-in-out;
  transform: scaleY(0);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.SelectBox .SelectBox_List .SelectBox_List_Content .SelectBox_List_Item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--px-20) var(--px-24);
  border-top: 1px solid #ccc;
}
.SelectBox .SelectBox_List .SelectBox_List_Content .SelectBox_List_Item .List_Item_Icon {
  margin-right: 10px;
}
.SelectBox .SelectBox_List .SelectBox_List_Content .SelectBox_List_Item .List_Item_Icon i {
  font-size: var(--px-13);
  line-height: var(--px-13px);
  color: #666;
}
.SelectBox .SelectBox_List .SelectBox_List_Content .SelectBox_List_Item .List_Item_Label {
  color: #666;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: var(--px-13);
  line-height: var(--px-13px);
  text-transform: uppercase;
}

.Page {
  position: relative;
  padding: var(--px-20) var(--px-0);
  background: var(--pageBackground);
}
.Page .Page_Header {
  padding: var(--px-0) var(--px-20);
  margin-bottom: 24px;
}
.Page .Page_Header .Page_Header_Title {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Icone {
  position: relative;
  margin-right: 6px;
  top: 3px;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Icone i {
  font-size: var(--px-14);
  line-height: var(--px-15px);
  color: var(--primary);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Icone.__is-bigger i {
  font-size: var(--px-20);
  line-height: var(--px-28px);
  color: var(--primary);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Text {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  position: relative;
  top: 2px;
  width: 100%;
  max-width: calc(100% - 65px);
  z-index: 1;
  font-size: var(--px-20);
  line-height: var(--px-25px);
  color: var(--secondary);
}
.Page .Page_Header .Page_Header_Title b {
  font-weight: 700;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin-left: 8px;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  padding: var(--px-8) var(--px-10);
  margin-right: 8px;
  background: var(--secondary);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item:last-child {
  margin-right: 0px;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item:active {
  outline: none;
  -webkit-animation: pulse;
  animation: pulse;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item:active .Actions_Item_Icone i {
  color: var(--headerActionForeground);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item .Actions_Item_Icone i {
  color: var(--secondaryContrast);
  font-size: var(--px-14);
  line-height: var(--px-14px);
  transition: color 0.2s ease-in-out;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item .Actions_Item_Icone i:hover {
  cursor: pointer;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item .Actions_Item_Text {
  font-family: "Raleway", sans-serif;
  position: relative;
  top: 0px;
  font-size: var(--px-12);
  line-height: var(--px-12px);
  font-weight: 700;
  color: var(--secondaryContrast);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro {
  overflow: hidden;
  align-items: unset;
  padding: var(--px-0) var(--px-0) var(--px-0) var(--px-0);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__is-highlighted {
  background: var(--primary);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__is-highlighted .Actions_Item_Icone i {
  color: var(--primaryContrast);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__is-highlighted .Actions_Item_Text {
  cursor: pointer;
  padding: 10px 20px !important;
  transition: background 0.2s ease-in-out;
  color: var(--primaryContrast);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__is-highlighted .Actions_Item_Text:hover {
  background: var(--primary);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro .Actions_Item_Icone {
  padding: 10px 10px 10px 20px;
  padding-left: 25px;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro .Actions_Item_Icone.Item_Icone_AbreFiltro {
  display: block;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro .Actions_Item_Icone.Item_Icone_LimpaFiltro {
  display: none;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro .Actions_Item_Text {
  display: flex;
  align-items: center;
  padding: var(--px-6) var(--px-10) var(--px-6) var(--px-0);
  padding-right: 25px;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__has-filter {
  background: var(--primary);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__has-filter .Actions_Item_Icone {
  cursor: pointer;
  border-right: 2px solid #eee;
  padding: 10px 20px;
  transition: background 0.2s ease-in-out;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__has-filter .Actions_Item_Icone:hover {
  background: var(--primary);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__has-filter .Actions_Item_Icone.Item_Icone_AbreFiltro {
  display: none;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__has-filter .Actions_Item_Icone.Item_Icone_LimpaFiltro {
  display: block;
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__has-filter .Actions_Item_Icone i {
  color: var(--primaryContrast);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__has-filter .Actions_Item_Text {
  cursor: pointer;
  padding: 10px 20px !important;
  transition: background 0.2s ease-in-out;
  color: var(--primaryContrast);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__has-filter .Actions_Item_Text:hover {
  background: var(--primary);
}
.Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item.Actions_Item_Filtro.__is-inactive {
  cursor: not-allowed;
  opacity: 60%;
}
@media screen and (max-width: 800px) {
  .Page .Page_Header .Page_Header_Title .Header_Title_Actions .Title_Actions_Item .Title_Actions_Item .Actions_Item_Text {
    display: none;
  }
}
.Page .Page_Header .Page_Header_Subtitle {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: var(--px-13);
  line-height: var(--px-15px);
  margin-top: var(--px-4px);
  color: var(--pageSubtitle);
}
.Page .Page_Tab {
  position: relative;
  margin-bottom: 20px;
}
.Page .Page_Tab .Page_Tab_Nav {
  display: flex;
  justify-content: stretch;
}
.Page .Page_Tab .Page_Tab_Nav .Tab_Nav_Item {
  font-size: var(--px-14);
  line-height: var(--px-18px);
  background: var(--pageTabNavBackground);
  flex: 1;
  font-weight: 400;
  transition: background 0.2s ease-in-out;
  text-align: center;
  font-family: "Raleway", sans-serif;
}
.Page .Page_Tab .Page_Tab_Nav .Tab_Nav_Item a {
  padding: var(--px-20) var(--px-24);
  color: var(--pageTabNavLink);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  height: 100%;
}
.Page .Page_Tab .Page_Tab_Nav .Tab_Nav_Item.__is-active {
  background: var(--primary);
}
.Page .Page_Tab .Page_Tab_Nav .Tab_Nav_Item.__is-active a {
  color: var(--primaryContrast);
}
.Page .Page_Tab .Page_Tab_Nav .Tab_Nav_Item:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
.Page .Page_Tab .Page_Tab_Nav .Tab_Nav_Item:hover {
  cursor: pointer;
}
.Page .Page_Tab .Page_Tab_Content {
  position: relative;
}
.Page .Page_Tab .Page_Tab_Content .Tab_Content_Footer {
  margin-top: 20px;
  padding: var(--px-0) var(--px-20) var(--px-20) var(--px-20);
}
.Page .Page_Tab .Page_Tab_Content .Tab_Content_Footer .Content_Footer_Item {
  position: relative;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
}
.Page .Page_Tab .Page_Tab_Content .Tab_Content_Footer .Content_Footer_Item:last-child {
  margin-bottom: 0px;
}
.Page .Page_Header_BackDesktop {
  margin-right: 16px;
  font-size: var(--px-14);
  line-height: var(--px-14);
  font-weight: 450;
  display: none;
  cursor: pointer;
}
@media screen and (min-width: 800px) {
  .Page .Page_Header_BackDesktop {
    display: block;
  }
}
.Page .Page_Header_BackDesktop a {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  color: var(--primary);
}
.Page .Page_Header_BackDesktop a i {
  color: var(--primary);
  font-size: var(--px-24);
  line-height: var(--px-23);
  margin-right: 8px;
}

.Button {
  display: block;
  padding: 0;
  margin: 0;
  width: max-content;
  background: none;
  border: none;
  outline: none;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out, border 0.2s ease-in-out;
  text-decoration: none;
}
.Button.Button_Popover {
  width: auto;
}
.Button.Button_Popover .Button_Content_Label {
  text-align: left;
}
.Button:hover {
  cursor: pointer;
}
.Button:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
.Button.__is-full {
  width: 100%;
}
.Button.__is-full .Button_Content {
  width: 100%;
}
.Button.__is-small .Button_Content {
  padding: 0px;
}
.Button.__is-small .Button_Content .Button_Content_Icon i {
  font-size: var(--px-14);
}
.Button.__is-small .Button_Content .Button_Content_Label {
  font-size: var(--px-12);
}
.Button.__is-inactive {
  opacity: 0.2;
  cursor: not-allowed;
}
.Button.__is-inactive .Button_Content:active, .Button.__is-inactive .Button_Content:hover {
  animation-play-state: paused;
}
.Button.__is-only-icon .Button_Content_Icon {
  margin: 0;
}
.Button .Button_Content {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out, border 0.15s ease-in-out;
  border-radius: 6px;
  transform: scale(1);
  overflow: hidden;
  padding: var(--px-10) var(--px-20) var(--px-12) var(--px-20);
}
.Button .Button_Content .Button_Content_Icon {
  transition: background 0.15s ease-in-out, color 0.15s ease-in-out, border 0.15s ease-in-out;
  margin: 0 3px;
}
.Button .Button_Content .Button_Content_Icon i {
  font-size: var(--px-19);
  line-height: var(--px-19px);
}
.Button .Button_Content .Button_Content_Label {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  margin: 0 3px;
  font-size: var(--px-14);
  line-height: var(--px-14px);
}
.Button .Button_Content:after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -35px 0 0 -35px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  content: "";
  opacity: 0;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.17);
}
.Button .Button_Content:active {
  animation: button;
  animation-duration: 0.3s;
}
.Button .Button_Content:active:after {
  animation: anim-effect-radomir 0.3s forwards;
}
.Button.Button_Primary.Button_Solid .Button_Content {
  border: 2px solid var(--primary);
  background: var(--primary);
}
.Button.Button_Primary.Button_Solid .Button_Content .Button_Content_Icon,
.Button.Button_Primary.Button_Solid .Button_Content .Button_Content_Label {
  color: var(--primaryContrast);
}
.Button.Button_Primary.Button_Outline .Button_Content {
  border: 2px solid var(--primary);
}
.Button.Button_Primary.Button_Outline .Button_Content .Button_Content_Icon,
.Button.Button_Primary.Button_Outline .Button_Content .Button_Content_Label {
  color: var(--primary);
}
.Button.Button_Primary:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 800px) {
  .Button.Button_Primary.__is-full-on-mobile {
    width: 100%;
  }
  .Button.Button_Primary.__is-full-on-mobile .Button_Content {
    width: 100%;
  }
}
.Button.Button_Primary.__is-full {
  width: 100%;
}
.Button.Button_Primary.__is-full .Button_Content {
  width: 100%;
}
.Button.Button_Primary.__is-small .Button_Content {
  padding: 0px;
}
.Button.Button_Primary.__is-small .Button_Content .Button_Content_Icon i {
  font-size: var(--px-14);
}
.Button.Button_Primary.__is-small .Button_Content .Button_Content_Icon,
.Button.Button_Primary.__is-small .Button_Content .Button_Content_Label {
  color: var(--primary);
}
.Button.Button_Primary.Button_Ghost .Button_Content {
  overflow: visible;
  border: 0px;
  background: #fff;
}
.Button.Button_Primary.Button_Ghost .Button_Content .Button_Content_Icon,
.Button.Button_Primary.Button_Ghost .Button_Content .Button_Content_Label {
  color: var(--primaryContrast);
}
.Button.Button_Primary.Button_Fade .Button_Content {
  border: 0px;
  background: none;
}
.Button.Button_Primary.Button_Fade .Button_Content .Button_Content_Icon,
.Button.Button_Primary.Button_Fade .Button_Content .Button_Content_Label {
  color: var(--primary);
}
.Button.Button_Secondary.Button_Solid .Button_Content {
  border: 2px solid var(--secondary);
  background: var(--secondary);
}
.Button.Button_Secondary.Button_Solid .Button_Content .Button_Content_Icon,
.Button.Button_Secondary.Button_Solid .Button_Content .Button_Content_Label {
  color: var(--secondaryContrast);
}
.Button.Button_Secondary.Button_Outline .Button_Content {
  border: 2px solid var(--secondary);
}
.Button.Button_Secondary.Button_Outline .Button_Content .Button_Content_Icon,
.Button.Button_Secondary.Button_Outline .Button_Content .Button_Content_Label {
  color: var(--secondary);
}
.Button.Button_Secondary:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 800px) {
  .Button.Button_Secondary.__is-full-on-mobile {
    width: 100%;
  }
  .Button.Button_Secondary.__is-full-on-mobile .Button_Content {
    width: 100%;
  }
}
.Button.Button_Secondary.__is-full {
  width: 100%;
}
.Button.Button_Secondary.__is-full .Button_Content {
  width: 100%;
}
.Button.Button_Secondary.__is-small .Button_Content {
  padding: 0px;
}
.Button.Button_Secondary.__is-small .Button_Content .Button_Content_Icon i {
  font-size: var(--px-14);
}
.Button.Button_Secondary.__is-small .Button_Content .Button_Content_Icon,
.Button.Button_Secondary.__is-small .Button_Content .Button_Content_Label {
  color: var(--secondary);
}
.Button.Button_Secondary.Button_Ghost .Button_Content {
  overflow: visible;
  border: 0px;
  background: #fff;
}
.Button.Button_Secondary.Button_Ghost .Button_Content .Button_Content_Icon,
.Button.Button_Secondary.Button_Ghost .Button_Content .Button_Content_Label {
  color: var(--secondaryContrast);
}
.Button.Button_Secondary.Button_Fade .Button_Content {
  border: 0px;
  background: none;
}
.Button.Button_Secondary.Button_Fade .Button_Content .Button_Content_Icon,
.Button.Button_Secondary.Button_Fade .Button_Content .Button_Content_Label {
  color: var(--secondary);
}
.Button.Button_Danger.Button_Solid .Button_Content {
  border: 2px solid #E74747;
  background: #E74747;
}
.Button.Button_Danger.Button_Solid .Button_Content .Button_Content_Icon,
.Button.Button_Danger.Button_Solid .Button_Content .Button_Content_Label {
  color: var(--secondaryContrast);
}
.Button.Button_Danger.Button_Outline .Button_Content {
  border: 2px solid #E74747;
}
.Button.Button_Danger.Button_Outline .Button_Content .Button_Content_Icon,
.Button.Button_Danger.Button_Outline .Button_Content .Button_Content_Label {
  color: #E74747;
}
.Button.Button_Danger:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 800px) {
  .Button.Button_Danger.__is-full-on-mobile {
    width: 100%;
  }
  .Button.Button_Danger.__is-full-on-mobile .Button_Content {
    width: 100%;
  }
}
.Button.Button_Danger.__is-full {
  width: 100%;
}
.Button.Button_Danger.__is-full .Button_Content {
  width: 100%;
}
.Button.Button_Danger.__is-small .Button_Content {
  padding: 0px;
}
.Button.Button_Danger.__is-small .Button_Content .Button_Content_Icon i {
  font-size: var(--px-14);
}
.Button.Button_Danger.__is-small .Button_Content .Button_Content_Icon,
.Button.Button_Danger.__is-small .Button_Content .Button_Content_Label {
  color: #E74747;
}
.Button.Button_Danger.Button_Ghost .Button_Content {
  overflow: visible;
  border: 0px;
  background: #fff;
}
.Button.Button_Danger.Button_Ghost .Button_Content .Button_Content_Icon,
.Button.Button_Danger.Button_Ghost .Button_Content .Button_Content_Label {
  color: var(--secondaryContrast);
}
.Button.Button_Danger.Button_Fade .Button_Content {
  border: 0px;
  background: none;
}
.Button.Button_Danger.Button_Fade .Button_Content .Button_Content_Icon,
.Button.Button_Danger.Button_Fade .Button_Content .Button_Content_Label {
  color: #E74747;
}
.Button.Button_Dark_Purple.Button_Solid .Button_Content {
  border: 2px solid var(--darkPurple);
  background: var(--darkPurple);
}
.Button.Button_Dark_Purple.Button_Solid .Button_Content .Button_Content_Icon,
.Button.Button_Dark_Purple.Button_Solid .Button_Content .Button_Content_Label {
  color: var(--secondaryContrast);
}
.Button.Button_Dark_Purple.Button_Outline .Button_Content {
  border: 2px solid var(--darkPurple);
}
.Button.Button_Dark_Purple.Button_Outline .Button_Content .Button_Content_Icon,
.Button.Button_Dark_Purple.Button_Outline .Button_Content .Button_Content_Label {
  color: var(--darkPurple);
}
.Button.Button_Dark_Purple:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 800px) {
  .Button.Button_Dark_Purple.__is-full-on-mobile {
    width: 100%;
  }
  .Button.Button_Dark_Purple.__is-full-on-mobile .Button_Content {
    width: 100%;
  }
}
.Button.Button_Dark_Purple.__is-full {
  width: 100%;
}
.Button.Button_Dark_Purple.__is-full .Button_Content {
  width: 100%;
}
.Button.Button_Dark_Purple.__is-small .Button_Content {
  padding: 0px;
}
.Button.Button_Dark_Purple.__is-small .Button_Content .Button_Content_Icon i {
  font-size: var(--px-14);
}
.Button.Button_Dark_Purple.__is-small .Button_Content .Button_Content_Icon,
.Button.Button_Dark_Purple.__is-small .Button_Content .Button_Content_Label {
  color: var(--darkPurple);
}
.Button.Button_Dark_Purple.Button_Ghost .Button_Content {
  overflow: visible;
  border: 0px;
  background: #fff;
}
.Button.Button_Dark_Purple.Button_Ghost .Button_Content .Button_Content_Icon,
.Button.Button_Dark_Purple.Button_Ghost .Button_Content .Button_Content_Label {
  color: var(--secondaryContrast);
}
.Button.Button_Dark_Purple.Button_Fade .Button_Content {
  border: 0px;
  background: none;
}
.Button.Button_Dark_Purple.Button_Fade .Button_Content .Button_Content_Icon,
.Button.Button_Dark_Purple.Button_Fade .Button_Content .Button_Content_Label {
  color: var(--darkPurple);
}
.Button.Button_Negative.Button_Solid .Button_Content {
  border: 2px solid var(--negative);
  background: var(--negative);
}
.Button.Button_Negative.Button_Solid .Button_Content .Button_Content_Icon,
.Button.Button_Negative.Button_Solid .Button_Content .Button_Content_Label {
  color: var(--negativeContrast);
}
.Button.Button_Negative.Button_Outline .Button_Content {
  border: 2px solid var(--negative);
}
.Button.Button_Negative.Button_Outline .Button_Content .Button_Content_Icon,
.Button.Button_Negative.Button_Outline .Button_Content .Button_Content_Label {
  color: var(--negative);
}
.Button.Button_Negative:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 800px) {
  .Button.Button_Negative.__is-full-on-mobile {
    width: 100%;
  }
  .Button.Button_Negative.__is-full-on-mobile .Button_Content {
    width: 100%;
  }
}
.Button.Button_Negative.__is-full {
  width: 100%;
}
.Button.Button_Negative.__is-full .Button_Content {
  width: 100%;
}
.Button.Button_Negative.__is-small .Button_Content {
  padding: 0px;
}
.Button.Button_Negative.__is-small .Button_Content .Button_Content_Icon i {
  font-size: var(--px-14);
}
.Button.Button_Negative.__is-small .Button_Content .Button_Content_Icon,
.Button.Button_Negative.__is-small .Button_Content .Button_Content_Label {
  color: var(--negative);
}
.Button.Button_Negative.Button_Ghost .Button_Content {
  overflow: visible;
  border: 0px;
  background: #fff;
}
.Button.Button_Negative.Button_Ghost .Button_Content .Button_Content_Icon,
.Button.Button_Negative.Button_Ghost .Button_Content .Button_Content_Label {
  color: var(--negativeContrast);
}
.Button.Button_Negative.Button_Fade .Button_Content {
  border: 0px;
  background: none;
}
.Button.Button_Negative.Button_Fade .Button_Content .Button_Content_Icon,
.Button.Button_Negative.Button_Fade .Button_Content .Button_Content_Label {
  color: var(--negative);
}
.Button.Button_Success.Button_Solid .Button_Content {
  border: 2px solid var(--success);
  background: var(--success);
}
.Button.Button_Success.Button_Solid .Button_Content .Button_Content_Icon,
.Button.Button_Success.Button_Solid .Button_Content .Button_Content_Label {
  color: var(--successContrast);
}
.Button.Button_Success.Button_Outline .Button_Content {
  border: 2px solid var(--success);
}
.Button.Button_Success.Button_Outline .Button_Content .Button_Content_Icon,
.Button.Button_Success.Button_Outline .Button_Content .Button_Content_Label {
  color: var(--success);
}
.Button.Button_Success:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 800px) {
  .Button.Button_Success.__is-full-on-mobile {
    width: 100%;
  }
  .Button.Button_Success.__is-full-on-mobile .Button_Content {
    width: 100%;
  }
}
.Button.Button_Success.__is-full {
  width: 100%;
}
.Button.Button_Success.__is-full .Button_Content {
  width: 100%;
}
.Button.Button_Success.__is-small .Button_Content {
  padding: 0px;
}
.Button.Button_Success.__is-small .Button_Content .Button_Content_Icon i {
  font-size: var(--px-14);
}
.Button.Button_Success.__is-small .Button_Content .Button_Content_Icon,
.Button.Button_Success.__is-small .Button_Content .Button_Content_Label {
  color: var(--success);
}
.Button.Button_Success.Button_Ghost .Button_Content {
  overflow: visible;
  border: 0px;
  background: #fff;
}
.Button.Button_Success.Button_Ghost .Button_Content .Button_Content_Icon,
.Button.Button_Success.Button_Ghost .Button_Content .Button_Content_Label {
  color: var(--successContrast);
}
.Button.Button_Success.Button_Fade .Button_Content {
  border: 0px;
  background: none;
}
.Button.Button_Success.Button_Fade .Button_Content .Button_Content_Icon,
.Button.Button_Success.Button_Fade .Button_Content .Button_Content_Label {
  color: var(--success);
}
.Button.Button_Dark.Button_Solid .Button_Content {
  border: 2px solid var(--dark);
  background: var(--dark);
}
.Button.Button_Dark.Button_Solid .Button_Content .Button_Content_Icon,
.Button.Button_Dark.Button_Solid .Button_Content .Button_Content_Label {
  color: var(--darkContrast);
}
.Button.Button_Dark.Button_Outline .Button_Content {
  border: 2px solid var(--dark);
}
.Button.Button_Dark.Button_Outline .Button_Content .Button_Content_Icon,
.Button.Button_Dark.Button_Outline .Button_Content .Button_Content_Label {
  color: var(--dark);
}
.Button.Button_Dark:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 800px) {
  .Button.Button_Dark.__is-full-on-mobile {
    width: 100%;
  }
  .Button.Button_Dark.__is-full-on-mobile .Button_Content {
    width: 100%;
  }
}
.Button.Button_Dark.__is-full {
  width: 100%;
}
.Button.Button_Dark.__is-full .Button_Content {
  width: 100%;
}
.Button.Button_Dark.__is-small .Button_Content {
  padding: 0px;
}
.Button.Button_Dark.__is-small .Button_Content .Button_Content_Icon i {
  font-size: var(--px-14);
}
.Button.Button_Dark.__is-small .Button_Content .Button_Content_Icon,
.Button.Button_Dark.__is-small .Button_Content .Button_Content_Label {
  color: var(--dark);
}
.Button.Button_Dark.Button_Ghost .Button_Content {
  overflow: visible;
  border: 0px;
  background: #fff;
}
.Button.Button_Dark.Button_Ghost .Button_Content .Button_Content_Icon,
.Button.Button_Dark.Button_Ghost .Button_Content .Button_Content_Label {
  color: var(--darkContrast);
}
.Button.Button_Dark.Button_Fade .Button_Content {
  border: 0px;
  background: none;
}
.Button.Button_Dark.Button_Fade .Button_Content .Button_Content_Icon,
.Button.Button_Dark.Button_Fade .Button_Content .Button_Content_Label {
  color: var(--dark);
}
.Button.Button_Gray.Button_Solid .Button_Content {
  border: 2px solid var(--grayContrast);
  background: var(--grayContrast);
}
.Button.Button_Gray.Button_Solid .Button_Content .Button_Content_Icon,
.Button.Button_Gray.Button_Solid .Button_Content .Button_Content_Label {
  color: var(--gray);
}
.Button.Button_Gray.Button_Outline .Button_Content {
  border: 2px solid var(--grayContrast);
}
.Button.Button_Gray.Button_Outline .Button_Content .Button_Content_Icon,
.Button.Button_Gray.Button_Outline .Button_Content .Button_Content_Label {
  color: var(--grayContrast);
}
.Button.Button_Gray:active {
  animation: button;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
}
@media screen and (max-width: 800px) {
  .Button.Button_Gray.__is-full-on-mobile {
    width: 100%;
  }
  .Button.Button_Gray.__is-full-on-mobile .Button_Content {
    width: 100%;
  }
}
.Button.Button_Gray.__is-full {
  width: 100%;
}
.Button.Button_Gray.__is-full .Button_Content {
  width: 100%;
}
.Button.Button_Gray.__is-small .Button_Content {
  padding: 0px;
}
.Button.Button_Gray.__is-small .Button_Content .Button_Content_Icon i {
  font-size: var(--px-14);
}
.Button.Button_Gray.__is-small .Button_Content .Button_Content_Icon,
.Button.Button_Gray.__is-small .Button_Content .Button_Content_Label {
  color: var(--grayContrast);
}
.Button.Button_Gray.Button_Ghost .Button_Content {
  overflow: visible;
  border: 0px;
  background: #fff;
}
.Button.Button_Gray.Button_Ghost .Button_Content .Button_Content_Icon,
.Button.Button_Gray.Button_Ghost .Button_Content .Button_Content_Label {
  color: var(--gray);
}
.Button.Button_Gray.Button_Fade .Button_Content {
  border: 0px;
  background: none;
}
.Button.Button_Gray.Button_Fade .Button_Content .Button_Content_Icon,
.Button.Button_Gray.Button_Fade .Button_Content .Button_Content_Label {
  color: var(--grayContrast);
}

.FormGroup {
  position: relative;
  margin-bottom: 24px;
}
.FormGroup.__is-inactive {
  opacity: 60%;
}
.FormGroup.__is-inactive input {
  border: none !important;
  cursor: not-allowed;
}
.FormGroup .FormGroup_Input input[type=time]::-webkit-clear-button {
  display: none;
}
.FormGroup .FormGroup_Input input[type=time]::-webkit-inner-spin-button {
  display: none;
}
.FormGroup:last-child {
  margin-bottom: 0px;
}
.FormGroup.__is-error .FormGroup_Input input[type=text],
.FormGroup.__is-error .FormGroup_Input input[type=password],
.FormGroup.__is-error .FormGroup_Input input[type=mail],
.FormGroup.__is-error .FormGroup_Input input[type=number],
.FormGroup.__is-error .FormGroup_Input input[type=tel],
.FormGroup.__is-error .FormGroup_Input input[type=email],
.FormGroup.__is-error .FormGroup_Input input[type=time],
.FormGroup.__is-error .FormGroup_Input input[type=url],
.FormGroup.__is-error .FormGroup_Input input[type=date],
.FormGroup.__is-error .FormGroup_Input select,
.FormGroup.__is-error .FormGroup_Input textarea {
  color: var(--danger);
}
.FormGroup.__is-error .FormGroup_Erro {
  opacity: 1;
  max-height: 999px;
  margin-top: 8px;
}
.FormGroup.__has-icon-right .FormGroup_Input input[type=text],
.FormGroup.__has-icon-right .FormGroup_Input input[type=password],
.FormGroup.__has-icon-right .FormGroup_Input input[type=mail],
.FormGroup.__has-icon-right .FormGroup_Input input[type=number],
.FormGroup.__has-icon-right .FormGroup_Input input[type=tel],
.FormGroup.__has-icon-right .FormGroup_Input input[type=email],
.FormGroup.__has-icon-right .FormGroup_Input input[type=time],
.FormGroup.__has-icon-right .FormGroup_Input input[type=url],
.FormGroup.__has-icon-right .FormGroup_Input ion-datetime {
  padding-right: 38px;
  text-align: right;
}
.FormGroup.__has-icon-right .FormGroup_Input span.FormGroup_Icon {
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 1px;
  font-weight: 700;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  z-index: 2;
  font-size: var(--px-13);
  padding-right: 10px;
  color: var(--text);
}
.FormGroup.__has-icon-right .FormGroup_Input span.FormGroup_Icon.__is-clickable {
  color: var(--primary);
}
.FormGroup.__has-icon-right .FormGroup_Input i.FormGroup_Icon {
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 1px;
  font-size: var(--px-13);
  color: var(--text);
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: var(--px-13);
  width: 40px;
}
.FormGroup.__has-icon-right .FormGroup_Input i.FormGroup_Icon.__is-clickable {
  color: var(--primary);
}
.FormGroup.__has-icon-left .FormGroup_Input input[type=text],
.FormGroup.__has-icon-left .FormGroup_Input input[type=password],
.FormGroup.__has-icon-left .FormGroup_Input input[type=mail],
.FormGroup.__has-icon-left .FormGroup_Input input[type=number],
.FormGroup.__has-icon-left .FormGroup_Input input[type=tel],
.FormGroup.__has-icon-left .FormGroup_Input input[type=email],
.FormGroup.__has-icon-left .FormGroup_Input input[type=time],
.FormGroup.__has-icon-left .FormGroup_Input input[type=url],
.FormGroup.__has-icon-left .FormGroup_Input ion-datetime {
  padding-left: 38px;
  text-align: right;
}
.FormGroup.__has-icon-left .FormGroup_Input span.FormGroup_Icon {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 1px;
  font-weight: 700;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  z-index: 2;
  font-size: var(--px-13);
  padding-left: 10px;
  color: var(--text);
}
.FormGroup.__has-icon-left .FormGroup_Input i.FormGroup_Icon {
  position: absolute;
  pointer-events: none;
  z-index: 2;
  top: 0;
  left: 1px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--px-13);
  width: 40px;
  color: var(--text);
}
.FormGroup .FormGroup_LabelAndTooltip {
  display: flex;
  align-items: center;
}
.FormGroup .FormGroup_LabelAndTooltip .FormGroup_Label {
  flex: 1;
  margin-right: 8px;
}
.FormGroup .FormGroup_LabelAndTooltip ds-tooltip {
  display: block;
  margin-bottom: 10px;
}
.FormGroup .FormGroup_Label {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  text-transform: normal;
  display: block;
  font-size: var(--px-13);
  line-height: var(--px-15px);
  margin-bottom: 10px;
  color: var(--primary);
}
.FormGroup .FormGroup_Input {
  position: relative;
}
.FormGroup .FormGroup_Input input[type=text],
.FormGroup .FormGroup_Input input[type=password],
.FormGroup .FormGroup_Input input[type=mail],
.FormGroup .FormGroup_Input input[type=number],
.FormGroup .FormGroup_Input input[type=tel],
.FormGroup .FormGroup_Input input[type=email],
.FormGroup .FormGroup_Input input[type=time],
.FormGroup .FormGroup_Input input[type=url],
.FormGroup .FormGroup_Input select,
.FormGroup .FormGroup_Input textarea,
.FormGroup .FormGroup_Input ion-datetime {
  border: 1px solid var(--gray);
  background: var(--formFieldBackground);
  color: var(--text);
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  border-radius: 6px;
  outline: none;
  width: 100%;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
  z-index: 1;
  position: relative;
  -webkit-appearance: unset;
  padding: var(--px-12) var(--px-10);
  font-size: var(--px-13);
  line-height: var(--px-13px);
}
.FormGroup .FormGroup_Input textarea {
  min-height: 150px;
}
.FormGroup .FormGroup_Input input[type=date],
.FormGroup .FormGroup_Input input[type=month],
.FormGroup .FormGroup_Input input[type=time] {
  padding: 12px 10px;
  text-align: left;
  font-family: "Raleway", sans-serif;
  font-size: var(--px-13);
  line-height: var(--px-13);
  font-weight: 500;
  border-radius: 6px;
  outline: none;
  width: 100%;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
  -webkit-appearance: unset;
  border: 1px solid var(--gray);
  background: var(--formFieldBackground);
  color: var(--text);
}
.FormGroup .FormGroup_Input input[type=date]::-webkit-calendar-picker-indicator,
.FormGroup .FormGroup_Input input[type=month]::-webkit-calendar-picker-indicator,
.FormGroup .FormGroup_Input input[type=time]::-webkit-calendar-picker-indicator {
  position: absolute;
  font-size: var(--px-13);
  padding-right: 10px;
  right: 8px;
  top: 8px;
  height: 100%;
  transform: translateY(-50%);
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
}
.FormGroup .FormGroup_Input input[type=date]::-webkit-calendar-picker-indicator:hover,
.FormGroup .FormGroup_Input input[type=month]::-webkit-calendar-picker-indicator:hover,
.FormGroup .FormGroup_Input input[type=time]::-webkit-calendar-picker-indicator:hover {
  background: none;
}
.FormGroup .FormGroup_Input input[type=date]::-webkit-datetime-edit,
.FormGroup .FormGroup_Input input[type=month]::-webkit-datetime-edit,
.FormGroup .FormGroup_Input input[type=time]::-webkit-datetime-edit {
  font-family: "Raleway";
}
.FormGroup .FormGroup_Input input[type=date]::-webkit-inner-spin-button,
.FormGroup .FormGroup_Input input[type=month]::-webkit-inner-spin-button,
.FormGroup .FormGroup_Input input[type=time]::-webkit-inner-spin-button {
  margin-right: 24px;
  cursor: pointer;
}
.FormGroup .FormGroup_Input input[type=date]::-webkit-clear-button,
.FormGroup .FormGroup_Input input[type=month]::-webkit-clear-button,
.FormGroup .FormGroup_Input input[type=time]::-webkit-clear-button {
  font-size: var(--px-20);
  margin-right: 8px;
  cursor: pointer;
}
.FormGroup .FormGroup_Input select:hover {
  cursor: pointer;
}
.FormGroup .FormGroup_Erro {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: var(--danger);
  opacity: 0;
  transition: 0.2s ease-in-out;
  max-height: 0px;
  font-size: var(--px-11);
  line-height: var(--px-11px);
}

.FormGroup_Label {
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  text-transform: normal;
  display: block;
  font-size: var(--px-13);
  line-height: var(--px-15px);
  margin-bottom: 10px;
  color: var(--primary);
}

.Breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: var(--px-0) var(--px-20);
  margin-bottom: 20px;
}
.Breadcrumb .Breadcrumb_Item {
  font-size: var(--px-10);
  line-height: var(--px-10px);
  margin-right: var(--px-5);
  padding-right: var(--px-10);
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  position: relative;
  color: var(--breadcrumb);
}
.Breadcrumb .Breadcrumb_Item:not(:last-child) {
  margin-bottom: 4px;
}
.Breadcrumb .Breadcrumb_Item:after {
  content: ">";
  display: block;
  position: absolute;
  top: 0px;
  right: 0px;
  color: var(--breadcrumb);
}
.Breadcrumb .Breadcrumb_Item:last-child {
  margin-right: 0;
}
.Breadcrumb .Breadcrumb_Item:last-child:after {
  display: none;
}

.TermoAceite {
  position: relative;
  border-radius: 6px;
  padding: var(--px-16);
  background: #efefef;
  border: 1px solid #ccc;
}
.TermoAceite .TermoAceite_Box {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.TermoAceite .TermoAceite_Box .TermoAceite_Box_Icone {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin-right: 8px;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  background: #fff;
}
.TermoAceite .TermoAceite_Box .TermoAceite_Box_Icone i {
  width: 9px;
  height: 9px;
  color: #fff;
}
.TermoAceite .TermoAceite_Box .TermoAceite_Box_Label {
  width: 14px;
  height: 14px;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  color: #707070;
}
.TermoAceite input[type=checkbox] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  z-index: 2;
}
.TermoAceite input[type=checkbox]:checked + label.TermoAceite_Box .TermoAceite_Box_Icone {
  background: var(--primary);
  border-color: var(--primary);
}

.SearchBox {
  background: #ddd;
}
.SearchBox .SearchBox_Form {
  display: flex;
  position: relative;
}
.SearchBox .SearchBox_Form .SearchBox_Form_Input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.SearchBox .SearchBox_Form .SearchBox_Form_Input input {
  width: 100%;
  border: none;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  background: none;
  padding: var(--px-21) var(--px-21);
  font-size: var(--px-13);
}
.SearchBox .SearchBox_Form .SearchBox_Form_Icone {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.SearchBox .SearchBox_Form .SearchBox_Form_Icone i {
  font-size: var(--px-19);
  line-height: var(--px-19px);
  color: #555;
}

@keyframes anim-open {
  0% {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
@keyframes anim-close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
}
.Lightbox {
  width: 100vw;
  height: 100vh;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
@supports (height: 100dvh) {
  .Lightbox {
    height: 100dvh;
  }
}
.Lightbox.__is-open {
  pointer-events: auto;
  z-index: 99999;
  opacity: 1;
}
.Lightbox.__is-open .Popup {
  pointer-events: auto;
  animation-name: anim-open;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  display: block;
  order: -1;
  position: relative;
}
.Lightbox.__is-close {
  z-index: 0;
  opacity: 0;
  pointer-events: none;
}
.Lightbox .Popup {
  width: max-content;
  height: fit-content;
  max-width: calc(100vw - 5%);
  max-height: calc(100vh - 5%);
  overflow-y: auto;
  background: var(--lightboxPopupBackground);
  border-radius: 8px;
  z-index: 999999;
  padding: 32px;
  opacity: 0;
  pointer-events: none;
  flex-shrink: 0;
  position: absolute;
  animation-name: anim-close;
  animation-duration: 0.3s;
  animation-fill-mode: backwards;
}
@media screen and (min-width: 500px) {
  .Lightbox .Popup {
    padding: 40px;
  }
}
@media screen and (min-width: 800px) {
  .Lightbox .Popup {
    max-width: 600px;
  }
}
.Lightbox .Popup .Popup_Close {
  position: absolute;
  right: 16px;
  top: 16px;
}
.Lightbox .Popup .Popup_Close i {
  font-size: var(--px-24);
  color: var(--modalClose);
}
.Lightbox .Popup .Popup_Header .Popup_Header_Title {
  color: var(--lightboxPopupHeaderTitle);
  font-size: var(--px-18);
  line-height: var(--px-24px);
  font-family: "Raleway", "Open Sans", sans-serif;
  font-weight: 700;
  text-align: center;
}
.Lightbox .Popup .Popup_Content {
  margin: var(--px-12) var(--px-0) var(--px-16) var(--px-0);
  position: relative;
}
.Lightbox .Popup .Popup_Content .Popup_Content_MsgDefault {
  color: var(--lightboxPopupContentMessage);
  font-size: var(--px-14);
  line-height: var(--px-21px);
  font-family: "Raleway", "Open Sans", sans-serif;
  font-weight: 600;
  text-align: center;
}
.Lightbox .Popup .Popup_Footer .Popup_Footer_Buttons {
  display: flex;
  margin: 0 -8px;
}
.Lightbox .Popup .Popup_Footer .Popup_Footer_Buttons .Button {
  margin: 0 8px;
  flex: 1;
  max-height: 100%;
}
.Lightbox .Popup .Popup_Footer .Popup_Footer_Buttons .Button .Button_Content {
  height: 100%;
}
.Lightbox .Popup.Popup_Sucesso {
  position: relative;
}

.InfoGroup {
  margin-bottom: 16px;
}
.InfoGroup.__is-bigger .InfoGroup_Label, .InfoGroup.__is-bigger .InfoGroup_Value {
  font-size: var(--px-15);
}
.InfoGroup:last-child {
  margin-bottom: 0px;
}
.InfoGroup ._InfoGroup_BaseText, .InfoGroup .InfoGroup_Value, .InfoGroup .InfoGroup_Label {
  font-family: "Raleway";
  font-size: var(--px-14);
  line-height: var(--px-18);
}
.InfoGroup .InfoGroup_Label {
  margin-bottom: var(--px-3);
  font-weight: 600;
  color: var(--primary);
}
.InfoGroup .InfoGroup_Label.__is-bolder {
  font-weight: bold;
}
.InfoGroup .InfoGroup_Value {
  font-weight: 400;
  color: var(--text);
}
.InfoGroup .InfoGroup_Value.__is-fading {
  opacity: 55%;
}
.InfoGroup .InfoGroup_Value.__is-highlighted {
  font-weight: 700;
}
.InfoGroup .InfoGroup_Value.__is-bolder {
  font-weight: bold;
}

.InfoGroupList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.InfoGroupList:last-child {
  margin-bottom: initial;
}
@media screen and (max-width: 400px) {
  .InfoGroupList {
    flex-direction: column;
  }
}
.InfoGroupList .InfoGroup {
  margin-bottom: 16px;
  flex-basis: calc(50% - 8px);
  max-width: calc(50% - 8px);
}
.InfoGroupList .InfoGroup:last-child, .InfoGroupList .InfoGroup:nth-last-child(2) {
  margin-bottom: 0px;
}
.InfoGroupList .InfoGroup:nth-child(even) {
  margin-left: 8px;
}
.InfoGroupList .InfoGroup.__is-full-width {
  flex-basis: 100%;
  max-width: 100%;
  margin: 0px 0px 16px 0px;
}
.InfoGroupList .InfoGroup.__is-full-width + .InfoGroup {
  margin-left: initial !important;
}
@media screen and (max-width: 400px) {
  .InfoGroupList .InfoGroup {
    max-width: 100%;
    margin-left: 0px !important;
  }
  .InfoGroupList .InfoGroup:nth-last-child(2) {
    margin-bottom: 16px;
  }
}

.Collapse {
  border-radius: 8px;
  margin: 16px 20px;
  overflow: hidden;
  background: var(--collapseBackground);
}
.Collapse.__is-open .Collapse_Header {
  margin-bottom: 8px;
  border-bottom: 1px solid var(--collapseBorderBottom);
}
.Collapse.__is-open .Collapse_Header .Collapse_Header_Icon i {
  transform: rotate(180deg);
}
.Collapse.__is-open .Collapse_Content {
  max-height: 9999999px;
  padding-bottom: 16px;
}
.Collapse .Collapse_Header {
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.Collapse .Collapse_Header .Collapse_Header_Title {
  font-family: "Raleway", sans-serif;
  font-size: var(--px-14);
  line-height: var(--px-18);
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 8px;
  color: var(--collapseTitle);
}
.Collapse .Collapse_Header .Collapse_Header_Icon {
  margin-left: auto;
}
.Collapse .Collapse_Header .Collapse_Header_Icon i {
  font-size: var(--px-16);
  transition: 0.3s ease-in-out;
  color: var(--collapseTitle);
}
.Collapse .Collapse_Content {
  position: relative;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  padding: 0px 16px;
}

.Popover {
  background: var(--popoverBackground);
}
.Popover .Popover_Actions ds-button {
  cursor: pointer;
}
.Popover .Popover_Actions ds-button:hover {
  background: #eee;
}

.ng2-flatpickr-input-container input {
  border: 1px solid var(--formFieldBorder);
  background: var(--formFieldBackground);
  color: var(--formFieldText);
  text-align: left !important;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  border-radius: 6px;
  outline: none;
  width: 100%;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
  z-index: 1;
  position: relative;
  -webkit-appearance: unset;
  padding: var(--px-12) var(--px-10);
  font-size: var(--px-13);
  line-height: var(--px-13px);
}

ion-toggle {
  --background-checked: var(--primary);
  zoom: 0.7;
}

.warning-box {
  display: flex;
  align-items: center;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 8px;
  background: var(--warningCardBackground);
  color: var(--textContrast);
}
.warning-box p {
  margin: 0px;
}
.warning-box i {
  margin-right: 16px;
  font-size: 18px;
}
@media screen and (min-width: 800px) {
  .warning-box {
    width: max-content;
    padding: 16px;
  }
}

.filter-items {
  display: flex;
  flex-wrap: wrap;
  padding: 0px var(--px-20);
}
.filter-items ui-filter-item {
  margin: 0 8px 8px 0;
}

.default-desktop-only {
  height: inherit;
  width: inherit;
  display: block;
}
@media screen and (max-width: 800px) {
  .default-desktop-only {
    display: none;
  }
}

.default-mobile-only {
  height: inherit;
  width: inherit;
  display: block;
}
@media screen and (min-width: 800px) {
  .default-mobile-only {
    display: none;
  }
}

* {
  transition: font-size 0.3s ease-out;
}

@font-face {
  font-family: Raleway, sans-serif;
  src: url("/src/assets/font/Raleway-Regular.ttf");
}
ion-content {
  --background: transparent;
}

textarea {
  resize: none;
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  body {
    height: calc(100vh - env(safe-area-inset-top));
    margin-top: env(safe-area-inset-top);
    background: var(--bodyBackground);
  }
}
@media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  body {
    height: calc(100vh - env(safe-area-inset-top));
    margin-top: env(safe-area-inset-top);
    background: var(--bodyBackground);
  }
}
@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  body {
    height: calc(100vh - env(safe-area-inset-top));
    margin-top: env(safe-area-inset-top);
    background: var(--bodyBackground);
  }
}
.ion-color-primary {
  --ion-color-base: var(--primary) !important;
  --ion-color-base-rgb: var(--ion-color-primary-rgb, 56, 128, 255) !important;
  --ion-color-contrast: var(--ion-color-primary-contrast, #fff) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-primary-contrast-rgb,
    255,
    255,
    255
  ) !important;
  --ion-color-shade: #000000 !important;
  --ion-color-tint: var(--primary) !important;
}

.ion-color-secondary {
  --ion-color-base: var(--primary) !important;
}

ion-title {
  background-color: var(--secondary);
  color: var(--secondaryContrast);
}

ion-card-title {
  color: var(--secondary);
  font-weight: 600;
}

ion-checkbox {
  --background-checked: var(--primary);
  --border-color-checked: var(--primary);
}

p {
  font-family: "Raleway", open-sans;
}

.__is-danger {
  color: var(--danger) !important;
}

.__is-primary {
  color: var(--primary) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: inset 0 0 0 30px white inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: black !important;
}

.ion-page {
  overflow: auto !important;
}

.cordova-camera-select {
  display: none !important;
}

input:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
}

.infinite-scroll-content-md .infinite-loading-text {
  color: var(--primary);
}

.LoadingSplash {
  width: 100vw;
  height: 100vh;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoadingSplash .LoadingSplash_Content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.LoadingSplash .LoadingSplash_Content .LoadingSplash_Content_Logo {
  background-size: 100%;
  width: 200px;
  height: 131px;
}
.LoadingSplash .LoadingSplash_Content .LoadingSplash_Content_Msg {
  font-family: "Raleway", sans-serif;
  font-size: var(--px-14);
  line-height: var(--px-21);
  font-weight: 700;
  color: var(--secondary);
  margin-top: 16px;
  text-align: center;
}
.LoadingSplash .LoadingSplash_Content .LoadingSplash_Content_Spin {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
.LoadingSplash .LoadingSplash_Content .LoadingSplash_Content_Spin .lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.LoadingSplash .LoadingSplash_Content .LoadingSplash_Content_Spin .lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--secondary);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.LoadingSplash .LoadingSplash_Content .LoadingSplash_Content_Spin .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.LoadingSplash .LoadingSplash_Content .LoadingSplash_Content_Spin .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.LoadingSplash .LoadingSplash_Content .LoadingSplash_Content_Spin .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.LoadingSplash .LoadingSplash_Content .LoadingSplash_Content_Spin .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.auto-width-popover {
  --width: auto;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.text-align-center {
  text-align: center;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.f1 {
  flex: 1;
}

.align-items-center {
  align-items: center;
}

.z-index-4 {
  z-index: 4;
}