.TabNav {
  width: 100%;
  padding: var(--px-0) var(--px-10);
  max-height: var(--px-59);
  height: 100%;
  margin-top: auto;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 15%);
  background: var(--pageTabNavBackground);

  transition: opacity .3s ease-in-out, bottom .4s ease-in-out;

  .TabNav_List {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;

    .TabNav_List_Item {
      position: relative;
      flex: 1;

      .List_Item_NewNotificationIndicator {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        color: #fff;
        position: absolute;
        top: -2px;
        right: -4px;
        line-height: var(--px-11);
        height: 15px;
        width: 15px;
        background: var(--primary);
        border-radius: 50%;
      }

      &:active,
      &.__is-active {
        a {
          .List_Item_Icon {
            i {
              color: var(--primary);
            }

            i.Item_Icon_Fake {
              color: var(--primary);
            }
          }

          .List_Item_Label {
            color: var(--primary);
          }
        }
      }

      &:active {
        animation: pulse;
        animation-duration: .2s;
      }

      a {
        padding: var(--px-10) var(--px-10);
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px 10px;
        text-decoration: none;
        outline: none;
        -webkit-tap-highlight-color: transparent;

        .List_Item_Icon {
          position: relative;

          i {
            font-size: var(--px-20);
            line-height: var(--px-20px);
            color: var(--text);
            transition: .1s ease-in-out color;
          }

          i.Item_Icon_Fake {
            color: var(--text);
            padding: 0;
            font-family: 'Raleway', 'Open Sans', sans-serif;
            font-size: var(--px-20);
            font-weight: 700;
            font-style: normal;
            transition: .1s ease-in-out color;
          }
        }

        .List_Item_Label {
          font-size: var(--px-10);
          line-height: var(--px-10);
          margin-top: var(--px-4);
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
          transition: .1s ease-in-out color;
          color: var(--text);
        }
      }
    }
  }

  &.__has-banner {
    .TabNav_Banner {
      display: flex;
    }
  }

  .TabNav_Banner {
    height: var(--px-77);
    width: 100vw;
    display: none;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -10px;
  }

  @media screen and (min-width: 800px) {
    opacity: 0;
    bottom: -200%;
  }

  @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
    padding-bottom: env(safe-area-inset-bottom);
  }

  @media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
    padding-bottom: env(safe-area-inset-bottom);
  }

  @media only screen and (device-width : 414px) and (device-height : 736px) and (-webkit-device-pixel-ratio : 3) {
    padding-bottom: env(safe-area-inset-bottom);
  }

}