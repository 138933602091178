.ListSelect {
  position: relative;

  .ListSelect_Content {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -5px;

    &.__is-full-list {
      .ListSelect_Content_Item {
        margin-bottom: 12px;
        width: 100%;
        text-align: center;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0px;
        }

        &.__is-selected {

          a,
          label {
            background: var(--primary);

            .Content_Item_Label {
              color: var(--primaryContrast);
            }
          }
        }

        a,
        label {
          padding: var(--px-12) var(--px-16);
          cursor: pointer;
          background: var(--lightGray);

          border: none;
          text-decoration: none;
          display: block;
          width: 100%;
          box-sizing: border-box;
          border-radius: 6px;
          transition: .2s ease-in-out;

          .Content_Item_Label {
            font-family: 'Raleway', sans-serif;
            font-weight: 700;
            color: var(--lightGrayContrast);

            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            transition: .2s ease-in-out;
            font-size: var(--px-14);
            line-height: var(--px-14px);
          }
        }
      }
    }

    &.__is-grid-list {
      .ListSelect_Content_Item {
        width: calc((100% / 3) - 10px);
        margin: 0 5px;
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;

        &.__is-selected {

          a,
          label {
            background: var(--primary);

            .Content_Item_Label {
              color: var(--primaryContrast);
            }
          }
        }

        a,
        label {
          padding: var(--px-12) var(--px-12);
          cursor: pointer;

          background: var(--lightGray);

          border: none;
          text-decoration: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          border-radius: 6px;
          transition: .2s ease-in-out;
          height: 100%;

          .Content_Item_Label {
            font-family: 'Raleway', sans-serif;
            font-weight: 700;
            color: var(--lightGrayContrast);

            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            transition: .2s ease-in-out;
            font-size: var(--px-14);
            line-height: var(--px-14px);
          }
        }
      }
    }
  }
}