@keyframes anim-open {
  0% {
    opacity: 0;
    transform: scale3d(1.1, 1.1, 1);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes anim-close {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale3d(0.9, 0.9, 0.9);
  }
}

.Lightbox {
  width: 100vw;
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh;
  }
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-in-out;

  &.__is-open {
    pointer-events: auto;
    z-index: 99999;
    opacity: 1;

    .Popup {
      pointer-events: auto;
      animation-name: anim-open;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      display: block;
      order: -1;
      position: relative;
    }
  }

  &.__is-close {
    z-index: 0;
    opacity: 0;
    pointer-events: none;
  }

  .Popup {
    width: max-content;
    height: fit-content;
    max-width: calc(100vw - 5%);
    max-height: calc(100vh - 5%);
    overflow-y: auto;
    background: var(--lightboxPopupBackground);
    border-radius: 8px;
    z-index: 999999;
    padding: 32px;
    opacity: 0;
    pointer-events: none;
    flex-shrink: 0;
    position: absolute;
    animation-name: anim-close;
    animation-duration: 0.3s;
    animation-fill-mode: backwards;

    @media screen and (min-width: 500px) {
      padding: 40px;
    }

    @media screen and (min-width:800px) {
      max-width: 600px;
    }

    .Popup_Close {
      position: absolute;
      right: 16px;
      top: 16px;

      i {
        font-size: var(--px-24);
        color: var(--modalClose);
      }
    }

    .Popup_Header {
      .Popup_Header_Title {
        color: var(--lightboxPopupHeaderTitle);
        font-size: var(--px-18);
        line-height: var(--px-24px);
        font-family: 'Raleway', 'Open Sans', sans-serif;
        font-weight: 700;
        text-align: center;
      }
    }

    .Popup_Content {
      margin: var(--px-12) var(--px-0) var(--px-16) var(--px-0);
      position: relative;

      .Popup_Content_MsgDefault {
        color: var(--lightboxPopupContentMessage);
        font-size: var(--px-14);
        line-height: var(--px-21px);
        font-family: 'Raleway',
        'Open Sans',
        sans-serif;
        font-weight: 600;
        text-align: center;
      }
    }

    .Popup_Footer {
      .Popup_Footer_Buttons {
        display: flex;
        margin: 0 -8px;

        .Button {
          margin: 0 8px;
          flex: 1;
          max-height: 100%;

          .Button_Content {
            height: 100%;
          }
        }
      }
    }
  }

  .Popup {
    &.Popup_Sucesso {
      position: relative;
    }
  }
}