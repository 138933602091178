.Container {
  padding: 0px 0px;
  max-height: calc(100% - var(--px-56) - var(--px-59));
  margin-top: var(--px-56);
  margin-bottom: var(--px-59);
  overflow: auto;
  position: relative;
  z-index: 1;
  height: 100%;
  background: var(--pageBackground);

  /* Essa classe deve ser adicionada ao .Container quando for adicionada ao tabnav*/
  &.__has-banner {
    max-height: calc(100vh - var(--px-56) - var(--px-59) - var(--px-77));
  }

  &.__has-modal {
    z-index: 999999999;
    position: relative;
    max-height: 100%;
    margin: 0;
  }

  @media screen and (min-width: 800px) {
    max-height: calc(100% - var(--px-72));
    margin-top: var(--px-72);
    margin-bottom: 0px;
  }
}