@mixin remove-when-width-is-above($px, $display) {
  display: $display;
  
  @media screen and (max-width: $px) {
    display: none;
  }
}

@mixin remove-when-width-is-below($px, $display) {
  display: $display;
  
  @media screen and (min-width: $px) {
    display: none;
  }
}

.default-desktop-only {
  height: inherit;
  width: inherit;
  @include remove-when-width-is-above(800px, block);
}

.default-mobile-only {
  height: inherit;
  width: inherit;
  @include remove-when-width-is-below(800px, block);
}