.SearchBox {
  background: #ddd;

  .SearchBox_Form {
    display: flex;
    position: relative;

    .SearchBox_Form_Input {
      // text-transform: uppercase;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      input {
        width: 100%;
        border: none;
        font-family: 'Raleway', sans-serif;
        font-weight: 500;
        background: none;
        padding: var(--px-21) var(--px-21);
        font-size: var(--px-13);
      }
    }

    .SearchBox_Form_Icone {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;

      i {
        font-size: var(--px-19);
        line-height: var(--px-19px);
        color: #555;
      }
    }
  }
}