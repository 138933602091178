.TermoAceite {
  position: relative;
  border-radius: 6px;
  padding: var(--px-16);
  background: #efefef;
  border: 1px solid #ccc;

  .TermoAceite_Box {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    .TermoAceite_Box_Icone {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background .2s ease-in-out, border-color .2s ease-in-out;
      margin-right: 8px;
      border-radius: 50px;
      width: 20px;
      height: 20px;
      border: 1px solid #999;
      background:#fff;

      i {
        width: 9px;
        height: 9px;
        color: #fff;
      }
    }

    .TermoAceite_Box_Label {
      width: 14px;
      height: 14px;
      font-family: 'Raleway',
      sans-serif;
      font-weight: 700;
      color: #707070;
    }
  }

  input[type="checkbox"] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0;
    z-index: 2;
  }

  input[type="checkbox"]:checked+label.TermoAceite_Box {
    .TermoAceite_Box_Icone {
      background: var(--primary);
      border-color: var(--primary);
    }
  }
}