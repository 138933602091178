.InfoGroup {
  margin-bottom: 16px;

  &.__is-bigger {
    .InfoGroup_Label, .InfoGroup_Value {
      font-size: var(--px-15);
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }

  ._InfoGroup_BaseText {
    font-family: "Raleway";
    font-size: var(--px-14);
    line-height: var(--px-18);
  }

  .InfoGroup_Label {
    @extend ._InfoGroup_BaseText;
    margin-bottom: var(--px-3); 
    font-weight: 600;
    color: var(--primary);  

    &.__is-bolder {
      font-weight: bold;
    }

  }

  .InfoGroup_Value {
    @extend ._InfoGroup_BaseText;
    font-weight: 400;
    color: var(--text);  

    &.__is-fading {
      opacity: 55%;
    }

    &.__is-highlighted {
      font-weight: 700;
    }

    &.__is-bolder {
      font-weight: bold;
    }
  }
}