@mixin createButtonClasses($bgColor, $fgColor) {
  &.Button_Solid {
    .Button_Content {
      border: 2px solid $bgColor;
      background: $bgColor;

      .Button_Content_Icon,
      .Button_Content_Label {
        color: $fgColor;
      }
    }
  }

  &.Button_Outline {
    .Button_Content {
      border: 2px solid $bgColor;

      .Button_Content_Icon,
      .Button_Content_Label {
        color: $bgColor;
      }
    }
  }

  &:active {
    animation: button;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
  }

  &.__is-full-on-mobile {
    @media screen and (max-width: 800px) {
      width: 100%;

      .Button_Content {
        width: 100%;
      }
    }
  }

  &.__is-full {
    width: 100%;

    .Button_Content {
      width: 100%;
    }
  }

  &.__is-small {
    .Button_Content {
      padding: 0px;

      .Button_Content_Icon {
        i {
          font-size: var(--px-14);
        }
      }

      .Button_Content_Icon,
      .Button_Content_Label {
        color: $bgColor;
      }
    }
  }

  &.Button_Ghost {
    .Button_Content {
      overflow: visible;
      border: 0px;
      background: #fff;

      .Button_Content_Icon,
      .Button_Content_Label {
        color: $fgColor;
      }
    }
  }

  &.Button_Fade {
    .Button_Content {
      border: 0px;
      background: none;

      .Button_Content_Icon,
      .Button_Content_Label {
        color: $bgColor;
      }
    }
  }
}

.Button {
  display: block;
  padding: 0;
  margin: 0;
  width: max-content;
  background: none;
  border: none;
  outline: none;
  transition: background .2s ease-in-out, color .2s ease-in-out, border .2s ease-in-out;
  text-decoration: none;

  &.Button_Popover {
    width: auto;

    .Button_Content_Label {
      text-align: left;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    animation: button;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
  }

  &.__is-full {
    width: 100%;

    .Button_Content {
      width: 100%;
    }
  }

  &.__is-small {
    .Button_Content {
      padding: 0px;

      .Button_Content_Icon {
        i {
          font-size: var(--px-14);
        }
      }

      .Button_Content_Label {
        font-size: var(--px-12);
      }
    }
  }

  &.__is-inactive {
    opacity: .2;
    cursor: not-allowed;

    .Button_Content {

      &:active,
      &:hover {
        animation-play-state: paused;
      }
    }
  }

  &.__is-only-icon {
    .Button_Content_Icon {
      margin: 0;
    }
  }

  .Button_Content {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid;
    transition: background .15s ease-in-out, color .15s ease-in-out, border .15s ease-in-out;
    border-radius: 6px;
    transform: scale(1);
    overflow: hidden;
    padding: var(--px-10) var(--px-20) var(--px-12) var(--px-20);

    .Button_Content_Icon {
      transition: background .15s ease-in-out, color .15s ease-in-out, border .15s ease-in-out;
      margin: 0 3px;

      i {
        font-size: var(--px-19);
        line-height: var(--px-19px);
      }
    }

    .Button_Content_Label {
      font-family: 'Raleway', sans-serif;
      font-weight: 700;
      margin: 0 3px;
      font-size: var(--px-14);
      line-height: var(--px-14px);
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -35px 0 0 -35px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      content: '';
      opacity: 0;
      pointer-events: none;
      background: rgba(255, 255, 255, 0.17);
    }

    &:active {
      animation: button;
      animation-duration: .3s;

      &:after {
        animation: anim-effect-radomir 0.3s forwards;
      }
    }
  }

  &.Button_Primary {
    @include createButtonClasses(var(--primary), var(--primaryContrast));
  }

  &.Button_Secondary {
    @include createButtonClasses(var(--secondary), var(--secondaryContrast));
  }

  &.Button_Danger {
    @include createButtonClasses(#E74747, var(--secondaryContrast));
  }

  &.Button_Dark_Purple {
    @include createButtonClasses(var(--darkPurple), var(--secondaryContrast));
  }

  &.Button_Negative {
    @include createButtonClasses(var(--negative), var(--negativeContrast));
  }

  &.Button_Success {
    @include createButtonClasses(var(--success), var(--successContrast));
  }

  &.Button_Dark {
    @include createButtonClasses(var(--dark), var(--darkContrast));
  }

  &.Button_Gray {
    @include createButtonClasses(var(--grayContrast), var(--gray));
  }
}