.SelectBox {
  background: #ddd;

  .SelectBox_Actual {
    color: #666;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    // text-transform: uppercase;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-size: var(--px-13);
    padding: var(--px-20) var(--px-20);

    i {
      font-size: var(--px-13);
      right: 20px;
      position: absolute;
      top: calc(50% - 7px);

      color: #666;
    }
  }

  &:hover {
    .SelectBox_List {
      max-height: 250px;
      overflow-y: auto;
      max-height: 250px;

      .SelectBox_List_Content {
        max-height: 100%;
        transform: scaleY(1);
      }
    }
  }

  .SelectBox_List {
    padding: 0 0px;
    overflow: hidden;
    transition: .2s ease-in-out;
    max-height: 0px;

    &.__is-open {
      max-height: 250px;
      overflow-y: auto;

      .SelectBox_List_Content {
        max-height: 100%;
        transform: scaleY(1);
      }
    }

    .SelectBox_List_Content {
      position: relative;
      display: block;
      max-height: 0px;
      transition: .2s ease-in-out;
      transform: scaleY(0);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .SelectBox_List_Item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: var(--px-20) var(--px-24);
        border-top: 1px solid #ccc;

        .List_Item_Icon {
          margin-right: 10px;

          i {
            font-size: var(--px-13);
            line-height: var(--px-13px);
            color: #666;
          }
        }

        .List_Item_Label {
          color: #666;
          font-family: 'Raleway', sans-serif;
          font-weight: 700;
          font-size: var(--px-13);
          line-height: var(--px-13px);
          text-transform: uppercase;
        }
      }
    }
  }
}