.Breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: var(--px-0) var(--px-20);
  margin-bottom: 20px;

  .Breadcrumb_Item {
    font-size: var(--px-10);
    line-height: var(--px-10px);
    margin-right: var(--px-5);
    padding-right: var(--px-10);
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    position: relative;
    color: var(--breadcrumb);

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &:after {
      content: '>';
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;
      color: var(--breadcrumb);
    }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }
    }
  }
}