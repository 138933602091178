.Block {
  box-shadow: 0 7px 7px -2px rgba(0, 0, 0, .08);
  border-top: 1px solid var(--blockBorderTop);
  margin-bottom: 20px;

  .Block_Title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--px-13);
    font-weight: 700;
    color: var(--primary);
    line-height: var(--px-15px);
    padding: var(--px-20) var(--px-20) var(--px-0) var(--px-20);

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .Block_Content {
    position: relative;
    margin-top: 16px;
  }

  .Block_Container {
    position: relative;
    padding: var(--px-20) var(--px-20) var(--px-20) var(--px-20);
  }

  .Block_Footer {
    padding: var(--px-0) var(--px-20) var(--px-20) var(--px-20);
    margin-top: 20px;

    .Block_Footer_Item {
      position: relative;
      margin-bottom: 14px;
      display: flex;
      justify-content: center;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}