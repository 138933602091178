.Header {
  background: var(--headerBackground);
  border-bottom: 1px solid var(--headerBorder);
  box-shadow: 0px 4px 4px -3px var(--headerShadow);

  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 2;
  padding: var(--px-8) var(--px-8);
  height: var(--px-56);

  .Header_Voltar {
    margin-right: auto;
    outline: none;
    padding: var(--px-8) var(--px-10);

    i {
      font-size: var(--px-20);
      color: var(--headerBackButton);
      transition: .2s ease-in-out color;
    }

    &:active {
      i {
        color: var(--primary);
      }

      outline: none;
      animation: pulse;
      animation-duration: .4s;
    }
  }

  .Header_Logo {
    max-width: 160px;
    position: absolute;
    top: calc(50% + 1px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      max-height: 45px;
    }

    .Header_Logo_Desktop {
      display: none;
    }

    .Header_Logo_Mobile {
      display: block;
    }
  }

  .Header_Atalho {
    position: relative;

    .Header_Atalho_Action {
      padding: var(--px-8) var(--px-10);

      cursor: pointer;

      i {
        font-size: var(--px-20);
        color: var(--headerAction);
        transition: .1s ease-in-out color;

        &:hover {
          color: var(--primary);
        }
      }

      i.Atalho_Action_FakeIcon {
        padding: 0;
        color: var(--headerAction);

        font-family: 'Raleway',
          'Open Sans',
          sans-serif;
        font-size: var(--px-20);
        font-weight: 700;
        font-style: normal;
        transition: .1s ease-in-out color;

        &:hover {
          color: var(--primary);
        }
      }

      &:active {
        i {
          color: var(--primary);
        }

        outline: none;
        animation: pulse;
        animation-duration: .4s;
      }
    }

    .Header_Atalho_Popup {
      display: none;
      position: absolute;
      top: 60px;
      background: var(--headerPopupBackground);
      border: 1px solid var(--headerPopupBorder);

      width: 400px;

      &.__is-left {
        right: -16px;

        &:after {
          content: " ";
          display: block;
          position: absolute;
          top: -21px;
          right: 12px;
          width: 0;
          height: 0;
          border-left: 21px solid var(--headerPopupBackground);
          border-right: 21px solid var(--headerPopupBackground);
          border-bottom: 21px solid var(--headerPopupBorder);
          z-index: 1;
        }

        &:before {
          content: " ";
          display: block;
          position: absolute;
          top: -20px;
          right: 13px;
          width: 0;
          height: 0;
          border-left: 20px solid var(--headerPopupBackground);
          border-right: 20px solid var(--headerPopupBackground);
          background: var(--headerPopupBackground);
          border-bottom: 20px solid var(--headerPopupBorder);
          z-index: 2;
        }
      }

      &.__is-right {
        left: -16px;

        &:after {
          content: " ";
          display: block;
          position: absolute;
          top: -21px;
          left: 12px;
          width: 0;
          height: 0;
          border-left: 21px solid var(--headerPopupBackground);
          border-right: 21px solid var(--headerPopupBackground);
          background: var(--headerPopupBackground);
          border-bottom: 21px solid var(--headerPopupBorder);
          z-index: 1;
        }

        &:before {
          content: " ";
          display: block;
          position: absolute;
          background: var(--headerPopupBackground);
          top: -20px;
          left: 13px;
          width: 0;
          height: 0;
          border-left: 20px solid var(--headerPopupBackground);
          background: var(--headerPopupBackground);
          border-right: 20px solid var(--headerPopupBackground);
          border-bottom: 20px solid var(--headerPopupBorder);
          z-index: 2;
        }
      }

      .Atalho_Popup_Header {
        width: 100%;
        padding: var(--px-24) var(--px-24) var(--px-16) var(--px-24);
        font-size: var(--px-20);

        .Popup_Header_Title {
          font-family: 'Raleway', sans-serif;
          font-weight: 700;
          color: var(--secondary);
        }

        .Popup_Header_Subtitle {
          font-family: 'Raleway', sans-serif;
          font-weight: 700;
          font-size: var(--px-13);
          margin-top: var(--px-4);
          color: var(--headerPopupSubtitle);
        }
      }

      .Atalho_Popup_Close {
        position: absolute;
        top: 0px;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--px-40);
        height: var(--px-40);

        &:active {
          outline: none;
          animation: pulse;
          animation-duration: .4s;
        }

        i {
          font-size: var(--px-16);
          color: var(--headerPopupCloseIcon);

          &:hover {
            cursor: pointer;
          }
        }
      }

      .Atalho_Popup_Loading {
        width: 100%;
        height: 100%;
        background: var(--headerPopupLoadingBackground);

        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 9;

        &.__is-active {
          display: flex;
        }

        .Popup_Loading_Icon {
          text-align: center;
          background-image: url('/assets/svg/loading.svg');
          background-position: left center;
          width: 70px;
          height: 60px;
          display: inline-block;
          background-size: 165%;
          background-repeat: no-repeat;
        }

        .Popup_Loading_Msg {
          font-family: 'Raleway', sans-serif;
          font-size: var(--px-14);
          font-weight: 700;
          color: var(--secondary);
          line-height: var(--px-21);
          margin-top: 8px;
          text-align: center;
        }
      }

      .Atalho_Popup_Content {
        position: relative;

        &::-webkit-scrollbar-track {
          background-color: var(--headerPopupScrollTrackBackground);
        }

        &::-webkit-scrollbar {
          width: 4px;
          background-color: var(--headerPopupScrollBarBackground);
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--headerPopupScrollThumbBackground);
          border: 0px solid var(--headerPopupScrollThumbBorder);
        }

        &.__has-padding {
          padding: var(--px-16) var(--px-24);
        }
      }
    }

    &.__is-open {
      .Header_Atalho_Popup {
        display: block;
      }
    }

    &.Header_Atalho_Notificacoes {


      .Header_Atalho_Popup {
        .Atalho_Popup_Content {
          max-height: 530px;
          overflow: auto;

          .ListActions {
            .ListActions_Item {
              .Header_ClickableNotificationLink {
                .ListActions_Item_Ico {
                  i {
                    color: var(--primary);
                  }

                  &.__is-new {
                    position: relative;

                    .Item_Ico_Contador {
                      position: absolute;
                      top: -3px;
                      right: -2px;
                      height: 12px;
                      width: 11px;
                      border-radius: 50%;
                      color: var(--primaryContrast);
                      background: var(--danger);
                    }
                  }
                }
              }
            }

            .ListActions_Separator {
              font-family: "Raleway";
              font-size: var(--px-13);
              font-weight: 600;
              margin: 0 20px;
              color: var(--primary);
            }

            .ListActions_Item+.ListActions_Separator {
              margin-top: 20px;
            }
          }
        }
      }

      .Header_Atalho_Action {
        position: relative;

        .Header_Notificacoes_Contador {
          position: absolute;
          top: -2px;
          right: -4px;
          font-size: var(--px-11);
          line-height: var(--px-11);
          height: 23px;
          width: 23px;
          font-weight: 800;
          background: var(--primary);
          color: var(--primaryContrast);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
        }
      }
    }

    &.Header_Atalho_Perfil {
      .Header_Atalho_Action {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          .Atalho_Configuracoes_Icon {
            i {
              color: var(--primary);
            }
          }

          .Atalho_Configuracoes_Arrow {
            i {
              color: var(--headerAction);
            }
          }
        }

        .Atalho_Configuracoes_Icon {
          i {
            font-size: var(--px-18);
            color: var(--headerAction);

            transition: .2s ease-in;
          }
        }

        .Atalho_Configuracoes_Name {
          display: none;
          margin-left: 12px;
          font-family: 'Raleway', sans-serif;
          font-size: var(--px-16);
          line-height: var(--px-16);
          font-weight: 700;
          color: var(--headerAction);
        }

        .Atalho_Configuracoes_Arrow {
          display: none;
          margin-left: 8px;

          i {
            font-size: var(--px-18);
            color: var(--headerAction);
            transition: .2s ease-in;
          }
        }
      }

      .Header_Atalho_Popup {
        .Atalho_Popup_Content {
          .ListActions {
            .ListActions_Item {
              label {
                cursor: pointer;
              }

              &.ListActions_Item_Logout {
                background: var(--danger);

                .ListActions_Item_Ico {
                  i,
                  span {
                    color: var(--dangerContrast);
                  }
                }

                .ListActions_Item_Label {
                  color: var(--dangerContrast);
                  font-weight: 700;
                  font-size: var(--px-16);
                }
              }
            }
          }
        }
      }
    }

    &.Header_Atalho_CentralAtendimento {
      .Header_Atalho_Popup {
        width: 580px;

        .Atalho_Popup_Content {
          padding: 8px 24px 24px 24px;
        }
        &.__is-sindico {
          width: 840px;
        }
      }
    }

    &.Header_Shortcut {
      display: none;

      @media screen and (min-width: 800px) {
        display: block;
      }

      .Header_Shortcut_Popup {
        .Shortcut_Popup_Content {
          max-height: 521px;
          overflow: auto;
        }
      }
    }

    &.Header_Atalho_Duvidas,
    &.Header_Atalho_CentralAtendimento,
    &.Header_Atalho_Notificacoes,
    &.Header_Atalho_PerguntasRespostas,
    &.Header_Atalho_Acessibilidade,
    &.Header_Atalho_Seguros {
      display: none;
    }
  }

  .Header_Search {
    margin-left: auto;
    display: none;

    .Header_Search_Input {
      margin: 0 16px;
      position: relative;

      .Search_Input_IconSearch {
        position: absolute;
        top: 0;
        left: 0px;
        width: 40px;
        height: 40px;
        display: flex;
        z-index: 2;
        justify-content: center;
        align-items: center;

        i {
          font-size: var(--px-14);
          color: var(--grayFading);
        }
      }

      .Search_Input_IconClean {
        position: absolute;
        top: 0;
        right: 0px;
        width: 32px;
        height: 40px;
        display: flex;
        z-index: 2;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity .2s ease-in-out;

        i {
          font-size: var(--px-14);
          color: 666;
        }
      }

      input {
        padding: 12px 12px 12px 40px;
        font-family: 'Raleway', sans-serif;
        font-size: var(--px-14);
        font-weight: 400;
        color: #666;
        background: #c9c9c9;
        border: none;
        border-radius: 6px;
        width: 100%;
        z-index: 1;
        position: relative;
        transition: background .2s ease-in-out,
        padding .2s ease-in-out;

        &::placeholder {
          color: #C9C9C9;
        }
      }

      input[type="text"]:focus {
        padding: 12px 32px 12px 40px;
      }

      input[type="text"]:focus~.Search_Input_IconClean {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 440px) {
    position: absolute;
  }

  @media screen and (max-width: 799px) {
    .Header_Atalho {
      .Header_Atalho_Popup {
        display: none !important;
      }
    }
  }

  @media screen and (min-width: 800px) {
    justify-content: flex-start;
    padding: var(--px-16) var(--px-24);
    height: var(--px-72);

    .Header_Logo {
      top: auto;
      left: auto;
      position: relative;
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
      min-width: 73px;
      max-width: none;

      img {
        height: 100%;
        max-height: 60px;
        width: 100%;
      }

      // margin-right: auto;
      // .Header_Logo_Desktop{
      //     display: block;
      //     max-height: none; 
      //     height: auto;
      // }
      // .Header_Logo_Mobile{
      //     display: none;
      // }
    }

    .Header_Voltar {
      display: none;
    }

    .Header_Search {
      // margin: 0;
      display: block;
    }

    .Header_Atalho {

      // margin-left: auto;
      &.Header_Atalho_Person {
        display: none;
      }

      &.Header_Atalho_Perfil {
        margin-left: 6%;
        min-width: 170px;
        margin-right: auto;

        .Header_Atalho_Action {
          max-width: max-content;

          .Atalho_Configuracoes_Icon {
            position: relative;

            i {
              color: var(--primary);
            }
          }

          .Atalho_Configuracoes_Name {
            display: block;
          }

          .Atalho_Configuracoes_Arrow {
            display: block;
          }
        }
      }

      &.Header_Atalho_Duvidas,
      &.Header_Atalho_CentralAtendimento,
      &.Header_Atalho_Notificacoes,
      &.Header_Atalho_Acessibilidade,
      &.Header_Atalho_PerguntasRespostas,
      &.Header_Atalho_Perfil,
      &.Header_Atalho_Seguros {
        display: block;
      }
    }
  }

  @media screen and (min-width: 800px) and (max-width: 950px) {
    .Header_Atalho {
      &.Header_Atalho_Perfil {
        margin-left: 6%;
        min-width: auto;

        .Header_Atalho_Action {
          .Atalho_Configuracoes_Name {
            display: none;
          }
        }
      }
    }
  }

  .EmailAdvice {
    position: absolute;
    bottom: 0px;
    left: 23px;

    i {
      font-size: var(--px-14) !important;
      color: var(--danger) !important;

      @media screen and (min-width: 800px) {
        font-size: var(--px-16) !important;
      }
    }
  }

  .Atalho_Configuracoes_Icon {
    position: relative;
  }

  .ListActions_Item {
    cursor: pointer;
  }

  .Header_Atalho_Action {
    position: relative;

    i {
      &:hover i {
        -webkit-text-stroke-color: var(--primary);
      }
    }
  }

  .overlayed-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    -webkit-text-stroke-width: 0.7px;
    right: 3px;
    bottom: 3px;

    -webkit-text-stroke-color: var(--text);

    i {
      font-size: 16px !important;

      color: var(--headerBackground) !important;
    }

    &:hover i {
      -webkit-text-stroke-color: var(--primary);
    }
  }
}
