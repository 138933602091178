.Collapse {
  border-radius: 8px;
  margin: 16px 20px;
  overflow: hidden;
  background: var(--collapseBackground);

  &.__is-open {
    .Collapse_Header {
      margin-bottom: 8px;
      border-bottom: 1px solid var(--collapseBorderBottom);

      .Collapse_Header_Icon {
        i {
          transform: rotate(180deg);
        }
      }
    }

    .Collapse_Content {
      max-height: 9999999px;
      padding-bottom: 16px;
    }
  }

  .Collapse_Header {
    padding: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .Collapse_Header_Title {
      font-family: 'Raleway', sans-serif;
      font-size: var(--px-14);
      line-height: var(--px-18);
      font-weight: 700;
      text-transform: uppercase;
      margin-right: 8px;
      color: var(--collapseTitle);
    }

    .Collapse_Header_Icon {
      margin-left: auto;

      i {
        font-size: var(--px-16);
        transition: .3s ease-in-out;
        color: var(--collapseTitle);
      }
    }
  }

  .Collapse_Content {
    position: relative;
    max-height: 0px;
    overflow: hidden;
    transition: max-height .3s ease-in-out;
    padding: 0px 16px;
  }
}