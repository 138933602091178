.FormGroup {
  position: relative;
  margin-bottom: 24px;

  &.__is-inactive {
    opacity: 60%;

    input {
      border: none !important;
      cursor: not-allowed;
    }
  }

  .FormGroup_Input {
    input[type="time"] {
      &::-webkit-clear-button {
        display: none;
      }

      &::-webkit-inner-spin-button {
        display: none;
      }
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }

  &.__is-error {
    .FormGroup_Input {

      input[type="text"],
      input[type="password"],
      input[type="mail"],
      input[type="number"],
      input[type="tel"],
      input[type="email"],
      input[type="time"],
      input[type="url"],
      input[type="date"],
      select,
      textarea {
        color: var(--danger);
      }
    }

    .FormGroup_Erro {
      opacity: 1;
      max-height: 999px;
      margin-top: 8px;
    }
  }

  &.__has-icon-right {
    .FormGroup_Input {

      input[type="text"],
      input[type="password"],
      input[type="mail"],
      input[type="number"],
      input[type="tel"],
      input[type="email"],
      input[type="time"],
      input[type="url"],
      ion-datetime {
        padding-right: 38px;
        text-align: right;
      }

      span.FormGroup_Icon {
        position: absolute;
        pointer-events: none;
        top: 0;
        right: 1px;
        font-weight: 700;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
        z-index: 2;
        font-size: var(--px-13);
        padding-right: 10px;
        color: var(--text);

        &.__is-clickable {
          color: var(--primary);
        }
      }

      i.FormGroup_Icon {
        position: absolute;
        pointer-events: none;
        top: 0;
        right: 1px;
        font-size: var(--px-13);
        color: var(--text);
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        font-size: var(--px-13);
        width: 40px;

        &.__is-clickable {
          color: var(--primary);
        }
      }
    }
  }

  &.__has-icon-left {
    .FormGroup_Input {

      input[type="text"],
      input[type="password"],
      input[type="mail"],
      input[type="number"],
      input[type="tel"],
      input[type="email"],
      input[type="time"],
      input[type="url"],
      ion-datetime {
        padding-left: 38px;
        text-align: right;
      }

      span.FormGroup_Icon {
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 1px;
        font-weight: 700;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
        z-index: 2;
        font-size: var(--px-13);
        padding-left: 10px;
        color: var(--text);
      }

      i.FormGroup_Icon {
        position: absolute;
        pointer-events: none;
        z-index: 2;
        top: 0;
        left: 1px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--px-13);
        width: 40px;
        color: var(--text);
      }
    }
  }

  .FormGroup_LabelAndTooltip {
    display: flex;
    align-items: center;

    .FormGroup_Label {
      flex: 1;
      margin-right: 8px;
    }

    ds-tooltip {
      display: block;
      margin-bottom: 10px;
    }
  }

  .FormGroup_Label {
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    text-transform: normal;
    display: block;
    font-size: var(--px-13);
    line-height: var(--px-15px);
    margin-bottom: 10px;
    color: var(--primary);
  }

  .FormGroup_Input {
    position: relative;

    input[type="text"],
    input[type="password"],
    input[type="mail"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    input[type="time"],
    input[type="url"],
    select,
    textarea,
    ion-datetime {
      border: 1px solid var(--gray);
      background: var(--formFieldBackground);
      color: var(--text);
      text-align: left;
      font-family: 'Raleway', sans-serif;
      font-weight: 500;
      border-radius: 6px;
      outline: none;
      width: 100%;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      display: block;
      z-index: 1;
      position: relative;
      -webkit-appearance: unset;
      padding: var(--px-12) var(--px-10);
      font-size: var(--px-13);
      line-height: var(--px-13px);
    }

    textarea {
      min-height: 150px;
    }

    input[type="date"],
    input[type="month"],
    input[type="time"] {
      padding: 12px 10px;
      text-align: left;
      font-family: "Raleway", sans-serif;
      font-size: var(--px-13);
      line-height: var(--px-13);
      font-weight: 500;
      border-radius: 6px;
      outline: none;
      width: 100%;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      display: block;
      position: relative;
      -webkit-appearance: unset;
      border: 1px solid var(--gray);
      background: var(--formFieldBackground);
      color: var(--text);

      &::-webkit-calendar-picker-indicator {
        position: absolute;
        font-size: var(--px-13);
        padding-right: 10px;
        right: 8px;
        top: 8px;
        height: 100%;
        transform: translateY(-50%);
        color: rgba(0, 0, 0, 0);
        cursor: pointer;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        -khtml-opacity: 0;

        &:hover {
          background: none;
        }
      }

      &::-webkit-datetime-edit {
        font-family: "Raleway";
      }

      &::-webkit-inner-spin-button {
        margin-right: 24px;
        cursor: pointer;
      }

      &::-webkit-clear-button {
        font-size: var(--px-20);
        margin-right: 8px;
        cursor: pointer;
      }
    }

    select:hover {
      cursor: pointer;
    }
  }

  .FormGroup_Erro {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    color: var(--danger);
    opacity: 0;
    transition: .2s ease-in-out;
    max-height: 0px;
    font-size: var(--px-11);
    line-height: var(--px-11px);
  }
}

.FormGroup_Label {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  text-transform: normal;
  display: block;
  font-size: var(--px-13);
  line-height: var(--px-15px);
  margin-bottom: 10px;
  color: var(--primary);
}