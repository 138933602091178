.ng2-flatpickr-input-container input {
  border: 1px solid var(--formFieldBorder);
  background: var(--formFieldBackground);
  color: var(--formFieldText);

  text-align: left !important;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  border-radius: 6px;
  outline:none;
  width:100%;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  display: block;
  z-index: 1;
  position: relative;
  -webkit-appearance: unset;
  padding: var(--px-12) var(--px-10);
  font-size: var(--px-13);
  line-height: var(--px-13px);
}