:root {
  --bodyBackground: #f1f1f1;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #ff6e0f;
  --primaryDark: #e66c26;
  --primaryContrast: #ffffff;
  --secondary: #003974;
  --secondaryContrast: #ffffff;
  --tertiary: #003974;
  --tertiaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --grayFading: #f8f8f8;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #ff6e0f;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #f1f1f1;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #7e9bb8;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #384e66;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #002a5e;
  --sidebarHeader: #134481;
  --sidebarBorderRightBackground: #90a8bd;
  --sidebarScollTrackColor: #002a5e;
  --sidebarScrollColor: #002a5e;
  --sidebarScrollThumbColor: rgb(81, 131, 192);
  --sidebarScrollThumbBorderColor: #002a5e;
  --sidebarTitle: var(--primary);
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: #003974;
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --bordaCardsGestaoAguardandoAnalise:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardProventosDescontosNomeColor: #003974;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--success);
  --iconFillColor: var(--primary);
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --modalSucessoBG:#E3E9FD;
  --modalSucessoHeaderCollor:#5f6371;
  --modalbodyTextCollor:#888888;
  --inputOption: #fff;
  --SidebarContentScrollbarTrack_BackgroundColor: #002A5E;
  --SidebarContentScrollbarThumb_BackgroundColor: rgba(112, 112, 112, 0.1);
  --SidebarContentScrollbarThumb_Border: 3px solid #002A5E;
  --FaleComOConsultorNomeDesktop: #002A5E;
}

:root[theme="Theme_Grayed"] {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #e66c26;
  --primaryDark: #e66c26;
  --primaryContrast: #ffffff;
  --secondary: #003974;
  --tertiary: #003974;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ccc;
  --lightGrayContrast: #666;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #555;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #e66c26;
  --formFieldBorder: #cecece;
  --formFieldBackground: #f5f5f5;
  --formFieldText: #666;
  --collapseBackground: #f1f1f1;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #cdcdcd;
  --headerActionForeground: #666;
  --pageBackground: #ececec;
  --pageSubtitle: #555;
  --pageTabNavBackground: #d8d8d8;
  --pageTabNavLink: #555555;
  --blockBorderTop: #ccc;
  --headerBackground: #f5f5f5;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #555;
  --headerAction: #555;
  --headerPopupBackground: #f5f5f5;
  --headerPopupBorder: #dadada;
  --headerPopupSubtitle: #333;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #333;
  --listActionBorderBottom: #ccc;
  --atalhosBackground: #777777;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #555;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #f5f5f5;
  --modalBackground: #f9f9f9;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #f0f0f0;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #555;
  --tabNavBackground: #f5f5f5;
  --tabNavListIconText: #555;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #909090;
  --chameAMotoHeaderMenuButton: #444;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #ddd;
  --homeOpacity: 1;
  --homeVerMaisBackground: #777777;
  --sidebarBackground: #292929;
  --sidebarHeader: #3f3f3f;
  --sidebarBorderRightBackground: var(--primary);
  --sidebarScollTrackColor: #292929;
  --sidebarScrollColor: #292929;
  --sidebarScrollThumbColor: #999;
  --sidebarScrollThumbBorderColor: #292929;
  --sidebarTitle: var(--primary);
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #666;
  --tooltip: #003974;
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #ccc;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--success);
  --iconFillColor: var(--primary);
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[theme="Theme_Dark"] {
  --bodyBackground: #111;
  --text: #efefef;
  --textContrast: #fff;
  --warningText: #d6c321;
  --warning: #e0d618;
  --warningCardBackground: #3f3f3f;
  --primary: #ff6e0f;
  --primaryDark: #e66c26;
  --primaryContrast: #ffffff;
  --secondary: #ffffff;
  --tertiary: #003974;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #000000;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #5ead3c;
  --successContrast: #ffffff;
  --successLight: #245446;
  --grayFading: #333;
  --lightGray: #353535;
  --lightGrayContrast: #888;
  --gray: #5a5a5a;
  --grayContrast: #eee;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #b5b5b5;
  --danger: #fb3030;
  --dangerContrast: #ffffff;
  --title: #fff;
  --formFieldLabel: #999;
  --formFieldBorder: #444444;
  --formFieldBackground: #2f2f2f;
  --formFieldText: #efefef;
  --collapseBackground: #333;
  --collapseTitle: #fff;
  --collapseBorderBottom: #707070;
  --headerActionBackground: #444;
  --headerActionForeground: #949494;
  --pageBackground: #252525;
  --pageSubtitle: #999;
  --pageTabNavBackground: #444;
  --pageTabNavLink: #8a8a8a;
  --blockBorderTop: #444;
  --headerBackground: #0a0303;
  --headerBorder: #1a1a1a;
  --headerShadow: rgba(0, 0, 0, 0);
  --headerBackButton: #7d7d7d;
  --headerAction: #949494;
  --headerPopupBackground: #151515;
  --headerPopupBorder: #2d2d2d;
  --headerPopupSubtitle: #999;
  --headerPopupCloseIcon: #fff;
  --headerPopupLoadingBackground: rgba(0, 0, 0, 0.8);
  --headerPopupScrollTrackBackground: #333;
  --headerPopupScrollBarBackground: #555;
  --headerPopupScrollThumbBackground: #555;
  --headerPopupScrollThumbBorder: #555;
  --listActionItemIcon: #eee;
  --listActionItemLabel: #eee;
  --listActionBorderBottom: #353535;
  --atalhosBackground: #353535;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #555;
  --greyCardBackground: #353535;
  --greyDarkCardBackground: #303030;
  --greyLightCardBackground: #444;
  --modalBackground: #272727;
  --modalClose: #fff;
  --modalScrollTrackBackground: #333;
  --modalScrollBarBackground: #555;
  --modalScrollThumbBackground: #555;
  --modalScrollThumbBorder: #555;
  --popoverBackground: #444;
  --lightboxPopupHeaderTitle: var(--primary);
  --lightboxPopupBackground: #222;
  --lightboxPopupContentMessage: #efefef;
  --tabNavBackground: #1a1a1a;
  --tabNavListIconText: #7d7d7d;
  --homeTitleColor: var(--primary);
  --chameAMotoHeaderMenuBackground: #555;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #efefef;
  --detalheEnvioDeDocumentoAnexoBackground: #353535;
  --informativeCardBorder: #00b;
  --informativeCardBackground: rgba(131, 123, 255, 0.5);
  --extratoMobileCardBorder: #707070;
  --extratoSeparatorBackground: #2a2a2a;
  --homeOpacity: 0.3;
  --homeVerMaisBackground: #353535;
  --sidebarBackground: #191919;
  --sidebarHeader: #323232;
  --sidebarBorderRightBackground: #3e3e3e;
  --sidebarScollTrackColor: #292929;
  --sidebarScrollColor: #292929;
  --sidebarScrollThumbColor: #999;
  --sidebarScrollThumbBorderColor: #292929;
  --sidebarTitle: var(--primary);
  --loadingBackground: rgba(0, 0, 0, 0.75);
  --biometriaGrayButton: #ddd;
  --tooltip: #003974;
  --borderFooterTextArea: #444;
  --envioDocumentoCadastrarInformativeCardBackground: #00b;
  --perguntasERespostasCardBorderBottom: #6a6767;
  --tableBorderColor: #fff;
  --tableText: #ddd;
  --tableBackgroundColor: #333;
  --tableHighlightedLine: #494949;
  --tableSublineBackgroundColor: #505050;
  --tableSublineAltBackgroundColor: #3d3d3d;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--success);
  --iconFillColor: var(--primary);
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt.hmg.marlin.com.br"], :root[customer="app.doitt.com.br"] {
  --bodyBackground: #f1f1f1;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #35948a;
  --primaryDark: #236861;
  --primaryContrast: #ffffff;
  --secondary: #3db4a9;
  --tertiary: #297a72;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #3db4a9;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #f1f1f1;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #7e9bb8;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #384e66;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: var(--primary);
  --sidebarHeader: #4ac7ba;
  --sidebarBorderRightBackground: #9ed9d4;
  --sidebarScollTrackColor: #4ac7ba;
  --sidebarScrollColor: #4ac7ba;
  --sidebarScrollThumbColor: var(--primary);
  --sidebarScrollThumbBorderColor: #4ac7ba;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: #003974;
  --borderFooterTextArea: #aaa;
  --perguntasERespostasCardBorderBottom: #ccc;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --cashBackMenuBackground: #384e66;
  --cashBackSubMenuBackground: #658bb3;
  --dashboardMenuBackground: #384e66;
  --dashboardSubMenuBackground: #658bb3;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--success);
  --iconFillColor: #4ac7ba;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="tema05.doitt.hmg.marlin.com.br"], :root[customer="sag-adm.doitt.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E3560;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #2180FC;
  --tertiary: #2063AD;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: var(--tertiary);
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--primaryDark);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #0E3560;
  --sidebarHeader: #2180FC;
  --sidebarBorderRightBackground: #7CB4FD;
  --sidebarScollTrackColor: #7CB4FD;
  --sidebarScrollColor: #7CB4FD;
  --sidebarScrollThumbColor: #7CB4FD;
  --sidebarScrollThumbBorderColor: #2180FC ;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--secondary);
  --iconFillColor: var(--tertiary);
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="app-piloto.doitt.com.br"], :root[customer="doitt-piloto.hmg.marlin.com.br"] {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #823400;
  --primaryDark: #823400;
  --primaryContrast: #ffffff;
  --secondary: #d35400;
  --tertiary: #973c00;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;
  --pageBackground: #eee;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #7e9bb8;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #384e66;
  --greyCardBackground: #fff;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: var(--primary);
  --sidebarHeader: #dd672c;
  --sidebarBorderRightBackground: #cc7f4c;
  --sidebarScollTrackColor: #d86f29;
  --sidebarScrollColor: #d86f29;
  --sidebarScrollThumbColor: var(--primary);
  --sidebarScrollThumbBorderColor: #d86f29;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--success);
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-brj.hmg.marlin.com.br"] {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #A7293C;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #B7344D;
  --tertiary: #91243A;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: var(--secondary);
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #B7344D;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #55666E;
  --sidebarHeader: #7e8689;
  --sidebarBorderRightBackground: #9f9f9f;
  --sidebarScollTrackColor: #55666E;
  --sidebarScrollColor: #55666E;
  --sidebarScrollThumbColor: #55666E;
  --sidebarScrollThumbBorderColor: #7E8689;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #EB3558;
  --iconFillColor: #A48B77;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-confiare.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E3560;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #2180FC;
  --tertiary: #2063AD;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: var(--tertiary);
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--primaryDark);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #0E3560;
  --sidebarHeader: #2180FC;
  --sidebarBorderRightBackground: #7CB4FD;
  --sidebarScollTrackColor: #7CB4FD;
  --sidebarScrollColor: #7CB4FD;
  --sidebarScrollThumbColor: #7CB4FD;
  --sidebarScrollThumbBorderColor: #2180FC ;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--secondary);
  --iconFillColor: var(--tertiary);
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-edk.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #A60002;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #CD2446;
  --tertiary: #91243A;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #9D6A6A;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #3D3D3D;
  --sidebarHeader: #726E6E;
  --sidebarBorderRightBackground: #ABABAB;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #726E6E;
  --sidebarScrollThumbBorderColor: #726E6E;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--secondary);
  --iconFillColor: #CD2424;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-g9.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #D90238;
  --primaryDark: #980144;
  --primaryContrast: #ffffff;
  --secondary: #B70935;
  --tertiary: #980144;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #C51563;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #1E1F1B;
  --sidebarHeader: #72817C;
  --sidebarBorderRightBackground: #A0AEA9;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #CD2446;
  --iconFillColor: #FF0550;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="doitt-imoveisgarcia.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #F66806;
  --primaryDark: #B93212;
  --primaryContrast: #ffffff;
  --secondary: #E8431D;
  --tertiary: #B93212;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #FF9212;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #1E1F1B;
  --sidebarHeader: #515155;
  --sidebarBorderRightBackground: #BDBDC0;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #A83F2A;
  --iconFillColor: #FFB660;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-adelina.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E528F;
  --primaryDark: #053D70;
  --primaryContrast: #ffffff;
  --secondary: #0873D2;
  --tertiary: #053D70;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #053D70;
  --sidebarHeader: #287CC7;
  --sidebarBorderRightBackground: #65A7E2;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #279CE9;
  --iconFillColor: #5CAE75;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-trustee.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #031B47;
  --primaryDark: #033D9F;
  --primaryContrast: #ffffff;
  --secondary: #2868D3;
  --tertiary: #033D9F;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #031B47;
  --sidebarHeader: #2868D3;
  --sidebarBorderRightBackground: #86ABE9;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #FF9200;
  --iconFillColor: #FF9200;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-selano.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #B44204;
  --primaryDark: #A52A2B;
  --primaryContrast: #ffffff;
  --secondary: #C94700;
  --tertiary: #A52A2B;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #A18D6B;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #938770;
  --sidebarHeader: #C9AB3E;
  --sidebarBorderRightBackground: #D6C3A0;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #C99831;
  --iconFillColor: #B44204;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-aldm.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #CF242D;
  --primaryDark: #7C0D12;
  --primaryContrast: #ffffff;
  --secondary: #F0474F;
  --tertiary: #7C0D12;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #648DAE;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #68797D;
  --sidebarHeader: #98A5A9;
  --sidebarBorderRightBackground:#ccd3d6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #B5274E;
  --iconFillColor: #F34351;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-lopescondominial.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #2E3192;
  --primaryDark: #1E3257;
  --primaryContrast: #ffffff;
  --secondary: #0095D9;
  --tertiary: #1E3257;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #1D7BDA;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #4A5A78;
  --sidebarHeader: #617AA8;
  --sidebarBorderRightBackground:#95ABD6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #1595CF;
  --iconFillColor: #5DD0FF;
}

:root[customer="doitt-flavioaguiar.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #37588B;
  --primaryDark: #0B0D52;
  --primaryContrast: #ffffff;
  --secondary: #29448B;
  --tertiary: #0B0D52;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #2B4670;
  --sidebarHeader: #4F68AA;
  --sidebarBorderRightBackground:#95ABD6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #238EF1;
  --iconFillColor: #5F84ED;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-belosnegocios.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E528F;
  --primaryDark: #053D70;
  --primaryContrast: #ffffff;
  --secondary: #0873D2;
  --tertiary: #053D70;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #053D70;
  --sidebarHeader: #287CC7;
  --sidebarBorderRightBackground: #65A7E2;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #279CE9;
  --iconFillColor: #5CAE75;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema01.doitt.hmg.marlin.com.br"], [customer="doitt-farzo.hmg.marlin.com.br"], [customer="farzo.doitt.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #A60002;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #CD2446;
  --tertiary: #91243A;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #9D6A6A;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #3D3D3D;
  --sidebarHeader: #726E6E;
  --sidebarBorderRightBackground: #ABABAB;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #726E6E;
  --sidebarScrollThumbBorderColor: #726E6E;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--secondary);
  --iconFillColor: #CD2424;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema02.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #A7293C;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #B7344D;
  --tertiary: #91243A;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: var(--secondary);
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #B7344D;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #55666E;
  --sidebarHeader: #7e8689;
  --sidebarBorderRightBackground: #9f9f9f;
  --sidebarScollTrackColor: #55666E;
  --sidebarScrollColor: #55666E;
  --sidebarScrollThumbColor: #55666E;
  --sidebarScrollThumbBorderColor: #7E8689;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #EB3558;
  --iconFillColor: #A48B77;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema03.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #CF242D;
  --primaryDark: #7C0D12;
  --primaryContrast: #ffffff;
  --secondary: #F0474F;
  --tertiary: #7C0D12;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #648DAE;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #68797D;
  --sidebarHeader: #98A5A9;
  --sidebarBorderRightBackground:#ccd3d6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #B5274E;
  --iconFillColor: #F34351;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema04.doitt.hmg.marlin.com.br"], [customer="doitt-home-hunters.hmg.marlin.com.br"], [customer="hhcondominios.doitt.com.br"] {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #031B47;
  --primaryDark: #033D9F;
  --primaryContrast: #ffffff;
  --secondary: #2868D3;
  --tertiary: #033D9F;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #031B47;
  --sidebarHeader: #2868D3;
  --sidebarBorderRightBackground: #86ABE9;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #FF9200;
  --iconFillColor: #FF9200;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema05.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E3560;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #2180FC;
  --tertiary: #2063AD;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: var(--tertiary);
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--primaryDark);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #0E3560;
  --sidebarHeader: #2180FC;
  --sidebarBorderRightBackground: #7CB4FD;
  --sidebarScollTrackColor: #7CB4FD;
  --sidebarScrollColor: #7CB4FD;
  --sidebarScrollThumbColor: #7CB4FD;
  --sidebarScrollThumbBorderColor: #2180FC ;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--secondary);
  --iconFillColor: var(--tertiary);
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema06.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #37588B;
  --primaryDark: #0B0D52;
  --primaryContrast: #ffffff;
  --secondary: #29448B;
  --tertiary: #0B0D52;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #2B4670;
  --sidebarHeader: #4F68AA;
  --sidebarBorderRightBackground:#95ABD6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #238EF1;
  --iconFillColor: #5F84ED;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema07.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E528F;
  --primaryDark: #053D70;
  --primaryContrast: #ffffff;
  --secondary: #0873D2;
  --tertiary: #053D70;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #053D70;
  --sidebarHeader: #287CC7;
  --sidebarBorderRightBackground: #65A7E2;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #279CE9;
  --iconFillColor: #5CAE75;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema08.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #2E3192;
  --primaryDark: #1E3257;
  --primaryContrast: #ffffff;
  --secondary: #0095D9;
  --tertiary: #1E3257;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #1D7BDA;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #4A5A78;
  --sidebarHeader: #617AA8;
  --sidebarBorderRightBackground:#95ABD6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #1595CF;
  --iconFillColor: #5DD0FF;
}
:root[customer="tema09.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #F66806;
  --primaryDark: #B93212;
  --primaryContrast: #ffffff;
  --secondary: #E8431D;
  --tertiary: #B93212;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #FF9212;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #1E1F1B;
  --sidebarHeader: #515155;
  --sidebarBorderRightBackground: #BDBDC0;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #A83F2A;
  --iconFillColor: #FFB660;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema10.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #D90238;
  --primaryDark: #980144;
  --primaryContrast: #ffffff;
  --secondary: #B70935;
  --tertiary: #980144;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #C51563;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #1E1F1B;
  --sidebarHeader: #72817C;
  --sidebarBorderRightBackground: #A0AEA9;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #CD2446;
  --iconFillColor: #FF0550;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema11.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #B44204;
  --primaryDark: #A52A2B;
  --primaryContrast: #ffffff;
  --secondary: #C94700;
  --tertiary: #A52A2B;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #A18D6B;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #938770;
  --sidebarHeader: #C9AB3E;
  --sidebarBorderRightBackground: #D6C3A0;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #C99831;
  --iconFillColor: #B44204;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema12.doitt.hmg.marlin.com.br"] {
  --bodyBackground: #f1f1f1;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #35948a;
  --primaryDark: #236861;
  --primaryContrast: #ffffff;
  --secondary: #3db4a9;
  --tertiary: #297a72;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #3db4a9;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #f1f1f1;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #7e9bb8;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #384e66;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: var(--primary);
  --sidebarHeader: #4ac7ba;
  --sidebarBorderRightBackground: #9ed9d4;
  --sidebarScollTrackColor: #4ac7ba;
  --sidebarScrollColor: #4ac7ba;
  --sidebarScrollThumbColor: var(--primary);
  --sidebarScrollThumbBorderColor: #4ac7ba;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: #003974;
  --borderFooterTextArea: #aaa;
  --perguntasERespostasCardBorderBottom: #ccc;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --cashBackMenuBackground: #384e66;
  --cashBackSubMenuBackground: #658bb3;
  --dashboardMenuBackground: #384e66;
  --dashboardSubMenuBackground: #658bb3;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--success);
  --iconFillColor: #4ac7ba;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
//Tema Usuário Define Acessibilidade
:root[theme="tema01"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #A60002;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #CD2446;
  --tertiary: #91243A;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #9D6A6A;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #3D3D3D;
  --sidebarHeader: #726E6E;
  --sidebarBorderRightBackground: #ABABAB;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #726E6E;
  --sidebarScrollThumbBorderColor: #726E6E;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--secondary);
  --iconFillColor: #CD2424;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema02"] {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #A7293C;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #B7344D;
  --tertiary: #91243A;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: var(--secondary);
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #B7344D;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #55666E;
  --sidebarHeader: #7e8689;
  --sidebarBorderRightBackground: #9f9f9f;
  --sidebarScollTrackColor: #55666E;
  --sidebarScrollColor: #55666E;
  --sidebarScrollThumbColor: #55666E;
  --sidebarScrollThumbBorderColor: #7E8689;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #EB3558;
  --iconFillColor: #A48B77;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema03"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #CF242D;
  --primaryDark: #7C0D12;
  --primaryContrast: #ffffff;
  --secondary: #F0474F;
  --tertiary: #7C0D12;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #648DAE;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #68797D;
  --sidebarHeader: #98A5A9;
  --sidebarBorderRightBackground:#ccd3d6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #B5274E;
  --iconFillColor: #F34351;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema04"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #031B47;
  --primaryDark: #033D9F;
  --primaryContrast: #ffffff;
  --secondary: #2868D3;
  --tertiary: #033D9F;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #031B47;
  --sidebarHeader: #2868D3;
  --sidebarBorderRightBackground: #86ABE9;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #FF9200;
  --iconFillColor: #FF9200;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema05"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E3560;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #2180FC;
  --tertiary: #2063AD;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: var(--tertiary);
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--primaryDark);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #0E3560;
  --sidebarHeader: #2180FC;
  --sidebarBorderRightBackground: #7CB4FD;
  --sidebarScollTrackColor: #7CB4FD;
  --sidebarScrollColor: #7CB4FD;
  --sidebarScrollThumbColor: #7CB4FD;
  --sidebarScrollThumbBorderColor: #2180FC ;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--secondary);
  --iconFillColor: var(--tertiary);
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema06"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #37588B;
  --primaryDark: #0B0D52;
  --primaryContrast: #ffffff;
  --secondary: #29448B;
  --tertiary: #0B0D52;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #2B4670;
  --sidebarHeader: #4F68AA;
  --sidebarBorderRightBackground:#95ABD6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #238EF1;
  --iconFillColor: #5F84ED;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema07"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E528F;
  --primaryDark: #053D70;
  --primaryContrast: #ffffff;
  --secondary: #0873D2;
  --tertiary: #053D70;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #053D70;
  --sidebarHeader: #287CC7;
  --sidebarBorderRightBackground: #65A7E2;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #279CE9;
  --iconFillColor: #5CAE75;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema08"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #2E3192;
  --primaryDark: #1E3257;
  --primaryContrast: #ffffff;
  --secondary: #0095D9;
  --tertiary: #1E3257;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #1D7BDA;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #4A5A78;
  --sidebarHeader: #617AA8;
  --sidebarBorderRightBackground:#95ABD6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #1595CF;
  --iconFillColor: #5DD0FF;
}
:root[theme="tema09"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #F66806;
  --primaryDark: #B93212;
  --primaryContrast: #ffffff;
  --secondary: #E8431D;
  --tertiary: #B93212;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #FF9212;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #1E1F1B;
  --sidebarHeader: #515155;
  --sidebarBorderRightBackground: #BDBDC0;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #A83F2A;
  --iconFillColor: #FFB660;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema10"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #D90238;
  --primaryDark: #980144;
  --primaryContrast: #ffffff;
  --secondary: #B70935;
  --tertiary: #980144;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #C51563;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #1E1F1B;
  --sidebarHeader: #72817C;
  --sidebarBorderRightBackground: #A0AEA9;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #CD2446;
  --iconFillColor: #FF0550;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema11"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #B44204;
  --primaryDark: #A52A2B;
  --primaryContrast: #ffffff;
  --secondary: #C94700;
  --tertiary: #A52A2B;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #A18D6B;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #938770;
  --sidebarHeader: #C9AB3E;
  --sidebarBorderRightBackground: #D6C3A0;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #C99831;
  --iconFillColor: #B44204;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[theme="tema12"] {
  --bodyBackground: #f1f1f1;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #35948a;
  --primaryDark: #236861;
  --primaryContrast: #ffffff;
  --secondary: #3db4a9;
  --tertiary: #297a72;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #3db4a9;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #f1f1f1;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #7e9bb8;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #384e66;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: var(--primary);
  --sidebarHeader: #4ac7ba;
  --sidebarBorderRightBackground: #9ed9d4;
  --sidebarScollTrackColor: #4ac7ba;
  --sidebarScrollColor: #4ac7ba;
  --sidebarScrollThumbColor: var(--primary);
  --sidebarScrollThumbBorderColor: #4ac7ba;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: #003974;
  --borderFooterTextArea: #aaa;
  --perguntasERespostasCardBorderBottom: #ccc;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --cashBackMenuBackground: #384e66;
  --cashBackSubMenuBackground: #658bb3;
  --dashboardMenuBackground: #384e66;
  --dashboardSubMenuBackground: #658bb3;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--success);
  --iconFillColor: #4ac7ba;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="doitt-belosnegocios.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E528F;
  --primaryDark: #053D70;
  --primaryContrast: #ffffff;
  --secondary: #0873D2;
  --tertiary: #053D70;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #053D70;
  --sidebarHeader: #287CC7;
  --sidebarBorderRightBackground: #65A7E2;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #279CE9;
  --iconFillColor: #5CAE75;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema01.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #A60002;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #CD2446;
  --tertiary: #91243A;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #9D6A6A;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #3D3D3D;
  --sidebarHeader: #726E6E;
  --sidebarBorderRightBackground: #ABABAB;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #726E6E;
  --sidebarScrollThumbBorderColor: #726E6E;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--secondary);
  --iconFillColor: #CD2424;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema02.doitt.hmg.marlin.com.br"] {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #A7293C;
  --primaryDark: var(--primary);
  --primaryContrast: #ffffff;
  --secondary: #B7344D;
  --tertiary: #91243A;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: var(--secondary);
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #B7344D;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #55666E;
  --sidebarHeader: #7e8689;
  --sidebarBorderRightBackground: #9f9f9f;
  --sidebarScollTrackColor: #55666E;
  --sidebarScrollColor: #55666E;
  --sidebarScrollThumbColor: #55666E;
  --sidebarScrollThumbBorderColor: #7E8689;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #EB3558;
  --iconFillColor: #A48B77;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema03.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #CF242D;
  --primaryDark: #7C0D12;
  --primaryContrast: #ffffff;
  --secondary: #F0474F;
  --tertiary: #7C0D12;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #648DAE;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #68797D;
  --sidebarHeader: #98A5A9;
  --sidebarBorderRightBackground:#ccd3d6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #B5274E;
  --iconFillColor: #F34351;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema04.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #031B47;
  --primaryDark: #033D9F;
  --primaryContrast: #ffffff;
  --secondary: #2868D3;
  --tertiary: #033D9F;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #031B47;
  --sidebarHeader: #2868D3;
  --sidebarBorderRightBackground: #86ABE9;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #FF9200;
  --iconFillColor: #FF9200;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}

:root[customer="tema06.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #37588B;
  --primaryDark: #0B0D52;
  --primaryContrast: #ffffff;
  --secondary: #29448B;
  --tertiary: #0B0D52;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --download-link: #3880FF;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --darkPurple: #793464
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --error: #E74747;
  --darkPurple: #793464;
  --blue: #5F84C7;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #2B4670;
  --sidebarHeader: #4F68AA;
  --sidebarBorderRightBackground:#95ABD6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #238EF1;
  --iconFillColor: #5F84ED;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema07.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #0E528F;
  --primaryDark: #053D70;
  --primaryContrast: #ffffff;
  --secondary: #0873D2;
  --tertiary: #053D70;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #4E84BB;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #053D70;
  --sidebarHeader: #287CC7;
  --sidebarBorderRightBackground: #65A7E2;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #279CE9;
  --iconFillColor: #5CAE75;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema08.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #2E3192;
  --primaryDark: #1E3257;
  --primaryContrast: #ffffff;
  --secondary: #0095D9;
  --tertiary: #1E3257;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #ff4545;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #1D7BDA;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #4A5A78;
  --sidebarHeader: #617AA8;
  --sidebarBorderRightBackground:#95ABD6;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #1595CF;
  --iconFillColor: #5DD0FF;
}
:root[customer="tema09.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #F66806;
  --primaryDark: #B93212;
  --primaryContrast: #ffffff;
  --secondary: #E8431D;
  --tertiary: #B93212;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #FF9212;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #1E1F1B;
  --sidebarHeader: #515155;
  --sidebarBorderRightBackground: #BDBDC0;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #A83F2A;
  --iconFillColor: #FFB660;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema10.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #D90238;
  --primaryDark: #980144;
  --primaryContrast: #ffffff;
  --secondary: #B70935;
  --tertiary: #980144;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #C51563;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #1E1F1B;
  --sidebarHeader: #72817C;
  --sidebarBorderRightBackground: #A0AEA9;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #CD2446;
  --iconFillColor: #FF0550;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema11.doitt.hmg.marlin.com.br"]  {
  --bodyBackground: #777;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #B44204;
  --primaryDark: #A52A2B;
  --primaryContrast: #ffffff;
  --secondary: #C94700;
  --tertiary: #A52A2B;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #d35400;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #fff;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;  
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #A18D6B;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: var(--tertiary);
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: #938770;
  --sidebarHeader: #C9AB3E;
  --sidebarBorderRightBackground: #D6C3A0;
  --sidebarScollTrackColor: var(--sidebarBackground);
  --sidebarScrollColor: var(--sidebarBackground);
  --sidebarScrollThumbColor: #72817C;
  --sidebarScrollThumbBorderColor: #72817C;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: var(--secondary);
  --borderFooterTextArea: #aaa;
  --envioDocumentoCadastrarInformativeCardBackground: yellow;
  --perguntasERespostasCardBorderBottom: #d9d9d9;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: #C99831;
  --iconFillColor: #B44204;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
:root[customer="tema12.doitt.hmg.marlin.com.br"] {
  --bodyBackground: #f1f1f1;
  --text: #707070;
  --textContrast: #000;
  --warningText: #a09323;
  --warning: #e4cf1e;
  --warningCardBackground: #ffe4bb;
  --primary: #35948a;
  --primaryDark: #236861;
  --primaryContrast: #ffffff;
  --secondary: #3db4a9;
  --tertiary: #297a72;
  --tertiaryContrast: #ffffff;
  --secondaryContrast: #ffffff;
  --negative: #e74747;
  --negativeContrast: #ffffff;
  --link: #5f84c7;
  --success: #6bab4f;
  --successContrast: #ffffff;
  --successLight: #d2eae3;
  --lightGray: #ddd;
  --lightGrayContrast: #707070;
  --grayFading: #f8f8f8;
  --gray: #dcdcdc;
  --grayContrast: #777;
  --dark: #333;
  --darkContrast: #ffffff;
  --breadcrumb: #c8c8c8;
  --danger: #d33131;
  --dangerContrast: #ffffff;
  --title: var(--secondary);
  --formFieldLabel: #3db4a9;
  --formFieldBorder: #cecece;
  --formFieldBackground: #fff;
  --formFieldText: #888;
  --collapseBackground: #f1f1f1;
  --collapseTitle: #605f5f;
  --collapseBorderBottom: #d9d9d9;
  --headerActionBackground: #e8e8e8;
  --headerActionForeground: #707070;
  --pageBackground: #fff;
  --pageSubtitle: #707070;
  --pageTabNavBackground: #e8e8e8;
  --pageTabNavLink: #707070;
  --blockBorderTop: #eee;
  --headerBackground: #fff;
  --headerBorder: #eee;
  --headerShadow: rgba(0, 0, 0, 0.05);
  --headerBackButton: #707070;
  --headerAction: #707070;
  --headerPopupBackground: #fff;
  --headerPopupBorder: #ccc;
  --headerPopupSubtitle: #707070;
  --headerPopupCloseIcon: #a00;
  --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
  --headerPopupScrollTrackBackground: #f0f0f0;
  --headerPopupScrollBarBackground: #fff;
  --headerPopupScrollThumbBackground: #dfdfdf;
  --headerPopupScrollThumbBorder: #fff;
  --listActionItemIcon: #707070;
  --listActionItemLabel: #707070;
  --listActionBorderBottom: #eee;
  --atalhosBackground: #7e9bb8;
  --atalhosBackgroundContrast: #fff;
  --blueCardHighlighted: #384e66;
  --greyCardBackground: #efefef;
  --greyDarkCardBackground: #e8e8e8;
  --greyLightCardBackground: #fff;
  --modalBackground: #fff;
  --modalClose: #e74747;
  --modalScrollTrackBackground: #f0f0f0;
  --modalScrollBarBackground: #fff;
  --modalScrollThumbBackground: #dfdfdf;
  --modalScrollThumbBorder: #fff;
  --popoverBackground: #fff;
  --lightboxPopupHeaderTitle: var(--secondary);
  --lightboxPopupBackground: #fff;
  --lightboxPopupContentMessage: #707070;
  --tabNavBackground: #fff;
  --tabNavListIconText: #707070;
  --homeTitleColor: var(--secondary);
  --chameAMotoHeaderMenuBackground: #eee;
  --chameAMotoHeaderMenuButton: #fff;
  --detalheEnvioDeDocumentoAnexo: #333;
  --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
  --informativeCardBorder: yellow;
  --informativeCardBackground: rgba(255, 253, 84, 0.7);
  --extratoMobileCardBorder: #d9d9d9;
  --extratoSeparatorBackground: #dfdfdf;
  --homeOpacity: 1;
  --homeVerMaisBackground: #658bb3;
  --sidebarBackground: var(--primary);
  --sidebarHeader: #4ac7ba;
  --sidebarBorderRightBackground: #9ed9d4;
  --sidebarScollTrackColor: #4ac7ba;
  --sidebarScrollColor: #4ac7ba;
  --sidebarScrollThumbColor: var(--primary);
  --sidebarScrollThumbBorderColor: #4ac7ba;
  --sidebarTitle: #fff;
  --loadingBackground: rgba(255, 255, 255, 0.8);
  --biometriaGrayButton: #444;
  --tooltip: #003974;
  --borderFooterTextArea: #aaa;
  --perguntasERespostasCardBorderBottom: #ccc;
  --tableBorderColor: #fff;
  --tableText: #000;
  --tableBackgroundColor: #f8f8f8;
  --tableHighlightedLine: #fff8df;
  --tableSublineBackgroundColor: #e8f5ff;
  --tableSublineAltBackgroundColor: #eff7fd;
  --cashBackMenuBackground: #384e66;
  --cashBackSubMenuBackground: #658bb3;
  --dashboardMenuBackground: #384e66;
  --dashboardSubMenuBackground: #658bb3;
  --buttonLocacaoIndicacaoBackground: #b8b2ec;
  --buttonLocacaoIndicacao: #363067;
  --buttonCompraVendaIndicacaoBackground:#9ED9D4;
  --buttonCompraVendaIndicacao:#236861;
  --buttonSegurosBackground:#F5A0DB;
  --buttonSegurosIndicacao:#793464;
  --buttonEnergiaBackground:#FFE4BB;
  --buttonEnergiaIndicacao:#684F35;
  --buttonPeriodicosBackground:#99DD7B;
  --buttonPeriodicosIndicacao:#2C501E;
  --chartDotColor: #5F84C7;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
  --buttonCollapse:#00397466;
  --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
  --verticalGradientBackground: linear-gradient(158.29deg, rgba(95, 132, 199, 0.2) 22.56%, rgba(184, 178, 236, 0.2) 72.28%), linear-gradient(186.42deg, #363067 40.87%, #3DB4A9 95.35%);
  --buttonComissaonewTableBackground: #658BB3;
  --tableHeader: var(--success);
  --iconFillColor: #4ac7ba;
  --sucessoFornecedorEncontrado: #081D58;
  --warningFornecedorNaoEncontrado: #FF4040;
  --envioDePagamentosIonColorChecked:#F59C00;
  --envioDePagamentosCardSelect:#F59C00;
  --selectInput:#DCDCDC;
  --cardAvisoBackGround: #fff8df;
  --cardAvisoBorder: #e4cf1e;
}
// :root[customer="protest.hmg.marlin.com.br"] {
//   --bodyBackground: #777;
//   --text: #707070;
//   --warningText: #a09323;
//   --warning: #e4cf1e;
//   --primary: #8b1428;
//   --primaryContrast: #ffffff;
//   --secondary: rgb(143, 12, 12);
//    --tertiary: #003974;
//    --tertiaryContrast: #ffffff;
//   --secondaryContrast: #ffffff;
//   --negative: #5e1a1a;
//   --negativeContrast: #ffffff;
//   --link: #5f84c7;
// --download-link: #3880FF;
//   --success: #6bab4f;
//   --successContrast: #ffffff;
//   --successLight: #d2eae3;
//   --lightGray: #ddd;
//   --lightGrayContrast: #707070;
//   --grayFading: #f8f8f8;
//   --gray: #dcdcdc;
//   --grayContrast: #777;
//   --dark: #333;
//   --darkContrast: #ffffff;
//   --breadcrumb: #C8C8C8;
//   --danger: #f31616;
//   --dangerContrast: #ffffff;
//   --title: var(--secondary);
//   --formFieldLabel: var(--primary);
//   --formFieldBorder: #cecece;
//   --formFieldBackground: #f6f6f6;
//   --formFieldText: #888;
//   --collapseBackground: #f1f1f1;
//   --collapseTitle: #605F5F;
//   --collapseBorderBottom: #d9d9d9;
//   --headerActionBackground: #e8e8e8;
//   --headerActionForeground: #707070;
//   --pageBackground: #fdfdfd;
//   --pageSubtitle: #707070;
//   --pageTabNavBackground: #E8E8E8;
//   --pageTabNavLink: #707070;
//   --blockBorderTop: #eee;
//   --headerBackground: #fff;
//   --headerBorder: #eee;
//   --headerShadow: rgba(0, 0, 0, .05);
//   --headerBackButton: #707070;
//   --headerAction: #707070;
//   --headerPopupBackground: #fff;
//   --headerPopupBorder: #ccc;
//   --headerPopupSubtitle: #707070;
//   --headerPopupCloseIcon: #a00;
//   --headerPopupLoadingBackground: rgba(255, 255, 255, 0.8);
//   --headerPopupScrollTrackBackground: #f0f0f0;
//   --headerPopupScrollBarBackground: #fff;
//   --headerPopupScrollThumbBackground: #dfdfdf;
//   --headerPopupScrollThumbBorder: #fff;
//   --listActionItemIcon: #707070;
//   --listActionItemLabel: #707070;
//   --listActionBorderBottom: #eee;
//   --atalhosBackground: #993030;
//   --atalhosBackgroundContrast: #fff;
//   --blueCardHighlighted: #650808;
//   --greyCardBackground: #eeeeee;
//   --greyDarkCardBackground: #e8e8e8;
//   --greyLightCardBackground: #fff;
//   --modalBackground: #fff;
//   --modalClose: #e74747;
//   --error: #E74747;
//   --darkPurple: #793464;
//   --blue: #5F84C7;
//   --modalScrollTrackBackground: #f0f0f0;
//   --modalScrollBarBackground: #fff;
//   --modalScrollThumbBackground: #dfdfdf;
//   --modalScrollThumbBorder: #fff;
//   --popoverBackground: #fff;
//   --lightboxPopupHeaderTitle: var(--secondary);
//   --lightboxPopupBackground: #fff;
//   --lightboxPopupContentMessage: #707070;
//   --tabNavBackground: #fff;
//   --tabNavListIconText: #707070;
//   --homeTitleColor: var(--secondary);
//   --chameAMotoHeaderMenuBackground: #eee;
//   --chameAMotoHeaderMenuButton: #fff;
//   --detalheEnvioDeDocumentoAnexo: #333;
//   --detalheEnvioDeDocumentoAnexoBackground: #f1f1f1;
//   --informativeCardBorder: yellow;
//   --informativeCardBackground: rgba(255, 253, 84, 0.7);
//   --extratoMobileCardBorder: #d9d9d9;
//   --extratoSeparatorBackground: #dfdfdf;
//   --homeOpacity: 1;
//   --homeVerMaisBackground: #658bb3;
//   --sidebarBackground: #641d29;
//   --sidebarHeader: #993344;
//   --sidebarBorderRightBackground: #9f3043;
//   --sidebarScollTrackColor: #862c3b;
//   --sidebarScrollColor: #862c3b;
//   --sidebarScrollThumbColor: #fff;
//   --sidebarScrollThumbBorderColor: #862c3b;
//   --sidebarTitle: #fff;
//   --loadingBackground: rgba(255, 255, 255, 0.8);
//   --biometriaGrayButton: #444;
//   --tooltip: var(--secondary);
//   --borderFooterTextArea: #aaa;
//   --envioDocumentoCadastrarInformativeCardBackground: yellow;
//   --perguntasERespostasCardBorderBottom: #d9d9d9;
//   --tableBorderColor: #fff;
//   --tableText: #000;
//   --tableBackgroundColor: #f8f8f8;
//   --tableHighlightedLine: #fff8df;
//   --tableSublineBackgroundColor: #e8f5ff;
//   --tableSublineAltBackgroundColor: #EFF7FD;
//   --gradientBackground: linear-gradient(98.07deg, rgba(95, 132, 199, 0.2) 68.96%, rgba(184, 178, 236, 0.2) 95.38%), linear-gradient(264.39deg, #363067 36.14%, #3DB4A9 103.56%);
// }
